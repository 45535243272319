import React from 'react';
import styles from './index.less';
import { Person } from '@/pages/enterpriseService/components/contactFR/index';

export interface IPersons extends Person {
  icon: string;
  callPhone: string;
}

export interface IContactItem {
  icon: string;
  title: string;
  completeTitle: string;
  personInfo?: Person[];
  onlyPhone?: IPersons[];

  [key: string]: any;
}

type TPCIProps = {
  contactItem: IContactItem;
};

const ContactItem: React.FC<TPCIProps> = ({ contactItem }) => {
  return (
    <>
      <img style={{ width: '48px', height: '48px' }} src={contactItem.icon} />
      <div style={{ marginLeft: '20px' }}>
        <div className={styles.contact_fr_item_title}>{contactItem.title}</div>
        {contactItem?.personInfo && contactItem.personInfo.length > 0 ? (
          contactItem.personInfo.map((item, index) => (
            <div style={{ marginTop: index === 0 ? '8px' : '4px' }} key={index}>
              {item.name}
              <span style={{ color: '#0082FC', marginLeft: '16px' }}>
                {item.phone}
              </span>
            </div>
          ))
        ) : (
          <div style={{ marginTop: '8px' }}>
            <span style={{ color: '#0082FC' }}>
              {contactItem.onlyPhone ? contactItem.onlyPhone[0].phone : null}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default ContactItem;
