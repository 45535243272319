import React, { useEffect, useState } from 'react';
import styles from './notAdded.less';

type Props = {};

const NotAdded: React.FC<Props> = () => {
  return (
    <div>
      <img
        className={styles.not_added_img}
        src={require('@/static/images/wenhao.png')}
      />
      <div className={styles.not_added_tip}>未添加内容，点击上方按钮添加</div>
    </div>
  );
};

export default NotAdded;
