import { requestGet, requestPut } from '@/utils/request';

export function getNotice(params: { limit: number }) {
  return requestGet(
    `/fcoin/api/announcement/open/list?limit=${params.limit}`,
    params,
  );
}

export function updateAvatar(params: { logo: string }) {
  return requestPut(`/service/api/company/logo?logo=${params.logo}`, params);
}

export function getExclusiveCustomerService() {
  return requestGet(`/service/api/company/specialist`, {});
}
