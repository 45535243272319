import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, message, Modal, Radio, Tooltip } from 'antd';
import styles from './index.less';
import UploadIcon from '@/components/dragCard/uploadIcon';
import { ECardType } from '@/enum/card';
import { TDragCardItem, TEditDragTableRef } from '@/types/common/index';
import EditDragTable from '@/components/editDragTable/editDragTable';
import { EyeInvisibleOutlined } from '@ant-design/icons';

type TCardEditModal = {
  visible: boolean;
  onCancel: () => void;
  activeCard?: TDragCardItem;
  onCardEdit: (cardData: TDragCardItem) => void; // 编辑卡片
  onCardCreate: (cardData: TDragCardItem) => void; // 创建卡片
};

const BIG_CARD_MAX = 16;
const SMALL_CARD_MAX = 6;

const CardEditModal: React.FC<TCardEditModal> = ({
  activeCard,
  onCancel,
  visible,
  onCardEdit,
  onCardCreate,
}) => {
  const [form] = Form.useForm();
  const [cardType, setCardType] = useState(
    activeCard ? activeCard.type : ECardType.small,
  );
  const [dataLength, setDataLength] = useState(0);
  const formInstanceRef = useRef<TEditDragTableRef>(null);
  const calcIconVisible = () => {
    if (cardType === ECardType.big) {
      return dataLength > BIG_CARD_MAX;
    }
    return dataLength > SMALL_CARD_MAX;
  };
  useEffect(() => {
    if (activeCard) {
      setDataLength(activeCard.cardItemList.length);
      setCardType(activeCard.type);
    }
  }, [activeCard]);
  return (
    <Modal
      destroyOnClose
      wrapClassName={styles.drag_card_edit_modal}
      open={visible}
      onCancel={onCancel}
      title={activeCard ? '卡片配置' : '新增卡片'}
      width="100vw"
      cancelText="取消"
      okText="确认"
      onOk={async () => {
        const data = await form.validateFields();
        if (formInstanceRef !== null && formInstanceRef.current !== null) {
          const formItem = await formInstanceRef.current.getData();
          console.log('formItem是');
          console.log(formItem);
          if (
            !Array.isArray(formItem) ||
            (Array.isArray(formItem) && formItem.length === 0)
          ) {
            message.warning('卡片中至少要有一个内容');
          } else {
            const id = activeCard?.id as string;
            if (id) {
              onCardEdit({
                id,
                ...data,
                cardItemList: formItem,
              });
            } else {
              onCardCreate({
                ...data,
                cardItemList: formItem,
              });
            }
          }
        }
      }}
    >
      <div className={styles.modal_inner}>
        <div className={styles.modal_inner_title}>卡片信息</div>
        <div className={styles.card_info_wrapper}>
          <Form preserve={false} form={form}>
            <div>
              <Form.Item
                name="logo"
                initialValue={activeCard?.logo}
                rules={[{ required: true, message: '请上传卡片icon' }]}
              >
                <UploadIcon />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                initialValue={activeCard?.name}
                label="卡片名称"
                name="name"
                rules={[{ required: true, message: '请输入卡片名称' }]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
              <Form.Item
                label="卡片跳转"
                name="url"
                initialValue={activeCard?.url}
              >
                <Input placeholder="可选，输入后卡片整体可点击跳转至设定的链接" />
              </Form.Item>
              <Form.Item
                label="卡片类型"
                name="type"
                initialValue={
                  activeCard ? Number(activeCard.type) : ECardType.small
                }
              >
                <Radio.Group
                  onChange={(e) => {
                    setCardType(e.target.value);
                  }}
                >
                  <Radio value={ECardType.small}>小卡片-最大可是内容3*2</Radio>
                  <Radio value={ECardType.big}>大卡片-最大可是内容8*2</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className={styles.modal_inner_title}>卡片内容</div>
        <Button
          type="primary"
          onClick={async () => {
            if (formInstanceRef !== null && formInstanceRef.current !== null) {
              formInstanceRef.current.addRow();
              setDataLength((prevState) => {
                let temp = prevState;
                temp = temp + 1;
                return temp;
              });
            }
          }}
        >
          添加内容
        </Button>
        <div style={{ position: 'relative' }}>
          <EditDragTable
            itemLimit={
              cardType === ECardType.small ? SMALL_CARD_MAX : BIG_CARD_MAX
            }
            onDelete={() => {
              setDataLength((prevState) => {
                let temp = prevState;
                temp = temp - 1;
                return temp;
              });
            }}
            ref={formInstanceRef}
            initData={activeCard?.cardItemList}
          />
          {calcIconVisible() && (
            <Tooltip title="下列内容讲不会在前台展示">
              <EyeInvisibleOutlined
                className={styles.visible_btn}
                style={{
                  top:
                    cardType === ECardType.small
                      ? 75 + (SMALL_CARD_MAX - 1) * 55
                      : 75 + BIG_CARD_MAX * 55,
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default CardEditModal;
