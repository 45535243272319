/**
 * 计算前台页面顶部标签的下划线
 */
export function calFrontTabsUnderLine(show: boolean = true) {
  let activeTab = document.getElementsByClassName('ant-tabs-tab-active')
    ? document.getElementsByClassName('ant-tabs-tab-active')[0]
    : null;
  let tabUnderLine = document.getElementsByClassName(
    'ant-tabs-ink-bar-animated',
  )
    ? (document.getElementsByClassName(
        'ant-tabs-ink-bar-animated',
      )[0] as HTMLElement)
    : null;
  setTimeout(() => {
    if (activeTab && tabUnderLine) {
      let underLineWidth = parseInt(String(activeTab.clientWidth)) / 2;
      let underLineOffset =
        parseInt(tabUnderLine.style.left) +
        parseInt(String(activeTab.clientWidth)) / 4;
      tabUnderLine.style.width = `${underLineWidth}px`;
      tabUnderLine.style.left = `${underLineOffset}px`;
      setTimeout(() => {
        if (tabUnderLine && show) {
          tabUnderLine.style.visibility = 'visible';
        }
      }, 300);
    }
  }, 100);
}

/**
 * 计算企业服务中的滑动Tab下划线
 */
export function calEnterpriseServiceTabsUnderLine() {
  let enterpriseTabs = document.querySelector('#enterpriseTabs');
  let activeTab = enterpriseTabs?.getElementsByClassName('ant-tabs-tab-active')
    ? enterpriseTabs?.getElementsByClassName('ant-tabs-tab-active')[0]
    : null;
  let tabUnderLine = enterpriseTabs?.getElementsByClassName(
    'ant-tabs-ink-bar-animated',
  )
    ? (enterpriseTabs?.getElementsByClassName(
        'ant-tabs-ink-bar-animated',
      )[0] as HTMLElement)
    : null;
  setTimeout(() => {
    if (activeTab && tabUnderLine) {
      let underLineWidth = 40;
      let underLineOffset =
        parseInt(tabUnderLine.style.left) +
        (parseInt(String(activeTab.clientWidth)) - underLineWidth) / 2;
      tabUnderLine.style.left = `${underLineOffset}px`;
      tabUnderLine.style.width = `40px`;
      setTimeout(() => {
        if (tabUnderLine) {
          tabUnderLine.style.width = `40px`;
          tabUnderLine.style.visibility = 'visible';
        }
      }, 100);
    }
  }, 100);
}
