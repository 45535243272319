import React, { useContext } from 'react';
import styles from './index.less';
import { TDragCardItem, TDragCardItemData } from '@/types/common/index';
import { Modal, Space, Tooltip } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';
import useGetDragInfo from '@/hooks/useGetDragInfo';
import cx from 'classnames';
import { ECardItemType, ECardType } from '@/enum/card';
import { DragCardContext } from '@/context/dragContext';
import { postBuried } from '@/utils/requests/requests_utils';
import { downloadFile } from '@/utils/index';

type TDragCardItemProps = {
  item: TDragCardItem;
  type?: 'PERSONAL' | 'INTERNAL';
  isMobile?: boolean;
};

const DragCardItem: React.FC<TDragCardItemProps> = ({
  type = 'PERSONAL',
  item,
  isMobile = false,
}) => {
  const { isEdit } = useGetDragInfo();
  const DragCardContextProps = useContext(DragCardContext);
  const { onCardChange, onDeleteCard } = DragCardContextProps;
  const filterCardItemList = (listItem: TDragCardItemData[]) => {
    // 如果在移动端，需过滤掉不在移动端展示的cardItemList item
    if (isMobile) {
      return listItem.filter((item) => item.mobileShow);
    }
    return listItem;
  };
  const renderItem = () => {
    const renderTitleCom = (
      cardItem: TDragCardItemData,
      isOne: boolean = true,
    ) => {
      return (
        <div
          key={cardItem.id}
          onClick={async (event) => {
            event.stopPropagation();
            event.preventDefault();
            if (isEdit) {
              return;
            }
            if (Number(cardItem.type) === ECardItemType.url) {
              window.open(cardItem.url);
            } else if (Number(cardItem.type) === ECardItemType.file) {
              // 下载文件
              downloadFile(cardItem.url);
            }
            await postBuried({
              portal: type === 'PERSONAL' ? '社区导航' : '内部服务',
              classification: item.name,
              menu: cardItem.title,
            });
          }}
          className={cx(
            styles.drag_card_item_item_title,
            !isEdit && styles.drag_card_item_item_title_click,
            isOne ? styles.one_column : styles.two_column,
          )}
        >
          {cardItem.title}
        </div>
      );
    };
    const oneColumnMax = item.type === ECardType.small ? 3 : 8;
    if (filterCardItemList(item.cardItemList).length <= oneColumnMax) {
      return filterCardItemList(item.cardItemList).map((cardItem) => {
        return renderTitleCom(cardItem);
      });
    } else {
      const array = filterCardItemList(item.cardItemList).slice(
        0,
        oneColumnMax * 2,
      );
      const leftArray = array.slice(0, oneColumnMax);
      const rightArray = array.slice(oneColumnMax);
      return (
        <div className={styles.drag_card_item_item_wrapper}>
          <div className={styles.left}>
            {leftArray.map((cardItem) => {
              return renderTitleCom(cardItem, false);
            })}
          </div>
          <div className={styles.right}>
            {rightArray.map((cardItem) => {
              return renderTitleCom(cardItem, false);
            })}
          </div>
        </div>
      );
    }
  };
  return (
    <div
      className={cx(
        styles.drag_card_item,
        !isEdit && item.url && styles.drag_card_item_url,
      )}
      key={item.id}
      onClick={async () => {
        if (!isEdit && item.url) {
          window.open(item.url);
          await postBuried({
            portal: type === 'PERSONAL' ? '社区导航' : '内部服务',
            classification: item.name,
          });
        }
      }}
    >
      <img src={item.logo} className={styles.drag_card_item_logo} />
      <div className={styles.drag_card_item_inner}>
        <Space className={styles.drag_card_item_title_wrapper}>
          <Space>
            <div className={styles.drag_card_item_title}>{item.name}</div>
            {item.url && <RightOutlined />}
          </Space>
          {isEdit && (
            <Space size={10}>
              <Tooltip title="编辑卡片">
                <EditOutlined
                  className={styles.icon}
                  onClick={() => {
                    onCardChange(item.id);
                  }}
                />
              </Tooltip>
              <Tooltip title="删除卡片">
                <DeleteOutlined
                  onClick={() => {
                    Modal.confirm({
                      icon: <QuestionCircleOutlined />,
                      title: '确认删除该卡片？',
                      content: '将整个删除卡片和卡片中已经配置的内容',
                      okType: 'danger',
                      okText: '删除',
                      onOk: () => {
                        onDeleteCard(item.id);
                      },
                    });
                  }}
                  className={styles.icon}
                />
              </Tooltip>
            </Space>
          )}
        </Space>
        {renderItem()}
        {/*<Row gutter={[16, 20]}>*/}
        {/*  {item.cardItemList.map((cardItem) => {*/}
        {/*    return (*/}
        {/*      <Col span={12} key={cardItem.id}>*/}
        {/*        <div*/}
        {/*          className={cx(*/}
        {/*            styles.drag_card_item_item_title,*/}
        {/*            !isEdit && styles.drag_card_item_item_title_click,*/}
        {/*          )}*/}
        {/*          onClick={(event) => {*/}
        {/*            event.stopPropagation();*/}
        {/*            event.preventDefault();*/}
        {/*            if (isEdit) {*/}
        {/*              return;*/}
        {/*            }*/}
        {/*            if (Number(cardItem.type) === ECardItemType.url) {*/}
        {/*              window.open(cardItem.url);*/}
        {/*            } else if (Number(cardItem.type) === ECardItemType.file) {*/}
        {/*              // 下载文件*/}
        {/*            }*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          {cardItem.title}*/}
        {/*        </div>*/}
        {/*      </Col>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</Row>*/}
      </div>
    </div>
  );
};
export default DragCardItem;
