import React, { useEffect, useState } from 'react';
import styles from './mobile.less';
import { useRequest } from 'umi';
import { getNotice } from '@/utils/requests/requests_enterprise';
import { NotificationOutlined } from '@ant-design/icons';
import { NoticeBar, Popup, Space } from 'antd-mobile';
import { NoticeItem } from '.';
import dayjs from 'dayjs';
import { postBuried } from '@/utils/requests/requests_utils';

type Props = {};

const NoticeCardMobile: React.FC<Props> = ({}) => {
  const [noticeList, setNoticeList] = useState<NoticeItem[]>([]);
  const [visible, setVisible] = useState(false);

  const getNoticeRequest = useRequest(
    () => {
      return getNotice({ limit: 7 });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setNoticeList(res);
      },
      onError: (e) => {},
    },
  );

  // 埋点
  const { run: postBuriedRun } = useRequest(
    (menu: string) => {
      return postBuried({
        portal: '服务平台',
        classification: '通知公告',
        menu,
      });
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    getNoticeRequest.run();
  }, []);

  return (
    <div className={styles.notice_card_mobile}>
      <NoticeBar
        icon={
          <NotificationOutlined
            style={{ fontSize: '18px', color: '#8097AD' }}
          />
        }
        content={
          <a
            style={{ display: 'flex', color: '#333333' }}
            href={noticeList[0]?.url}
            target="_blank"
          >
            {noticeList[1]?.importance === '重要' && (
              <div className={styles.notice_card_list_item_importance_mobile}>
                {noticeList[1]?.importance}
              </div>
            )}
            {noticeList[3]?.title}
          </a>
        }
        color="alert"
        extra={
          <Space style={{ '--gap': '12px' }}>
            <span
              style={{ cursor: 'pointer', color: '#0082fc' }}
              onClick={() => setVisible(true)}
            >
              更多
            </span>
          </Space>
        }
        style={{
          '--background-color': 'none',
          '--border-color': 'none',
          '--text-color': '#333333',
          width: '100%',
          padding: '0px',
        }}
      />
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        showCloseButton
        onClose={() => {
          setVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '40vh',
        }}
      >
        <div className={styles.notice_card_mobile_popup_title}>通知公告</div>
        {noticeList?.map((item, index) => (
          <div
            className={styles.notice_card_mobile_popup_item}
            key={index}
            onClick={() => {
              postBuriedRun(`notice_${index + 1}`);
            }}
          >
            <a href={item.url} target="_blank">
              <div className={styles.notice_card_mobile_popup_item_title}>
                {item.importance === '重要' && (
                  <div className={styles.notice_card_mobile_popup_importance}>
                    {item.importance}
                  </div>
                )}
                <div className={styles.notice_card_mobile_popup_item_text}>
                  {item.title}
                </div>
              </div>
              <div className={styles.notice_card_mobile_popup_item_date}>
                {dayjs(item.putOnTime).format('YYYY-MM-DD')}
              </div>
            </a>
          </div>
        ))}
      </Popup>
    </div>
  );
};

export default NoticeCardMobile;
