import React from 'react';
import styles from './index.less';
import { ComponentWrapper } from '@digit-fe/digit-component';
import { useModel } from '@@/plugin-model/useModel';
import { canUseTechSupport } from '@/utils/enterprise';
import ContactItem, {
  IContactItem,
} from '@/pages/enterpriseService/components/contactFR/contactItem';
import MobileContactItem from '@/pages/enterpriseService/components/contactFR/mobileContactItem';

type Props = {};

const ContactFR: React.FC<Props> = () => {
  const { enterpriseAuth, enterpriseInfo } = useModel(
    'useEnterpriseModel',
    (model) => ({
      enterpriseAuth: model.enterpriseAuth,
      enterpriseInfo: model.enterpriseInfo,
    }),
  );

  // 是否开通了服务
  const isCertDone = canUseTechSupport(enterpriseAuth);

  const notCertContactInfos: IContactItem[] = [
    {
      title: '售前咨询',
      icon: `${process.env.ICON_PATH}/phone.png`,
      completeTitle: '售前咨询电话',
      pcShow: true,
      notPCShow: true,
      onlyPhone: [
        {
          phone: '400-811-8890 转 1',
          callPhone: '400-811-8890',
          icon: `${process.env.ICON_PATH}/phone.png`,
        },
      ],
    },
    {
      title: '技术支持',
      icon: `${process.env.ICON_PATH}/technical_support.png`,
      completeTitle: '技术支持',
      pcShow: false,
      notPCShow: true,
      onlyPhone: [
        {
          name: '大陆',
          phone: '400-811-8890 转 2',
          callPhone: '400-811-8890',
          icon: `${process.env.ICON_PATH}/technical_support.png`,
        },
        {
          name: '海外',
          phone: '(+86) 0510-66758729',
          callPhone: '(+86) 0510-66758729',
          icon: `${process.env.ICON_PATH}/overseas.png`,
        },
      ],
    },
    {
      title: '技术支持电话（大陆）',
      icon: `${process.env.ICON_PATH}/technical_support.png`,
      completeTitle: '技术支持电话（大陆）',
      pcShow: true,
      notPCShow: false,
      onlyPhone: [
        {
          phone: '400-811-8890 转 2',
          callPhone: '400-811-8890',
          icon: `${process.env.ICON_PATH}/technical_support.png`,
        },
      ],
    },
    {
      title: '技术支持（海外）',
      icon: `${process.env.ICON_PATH}/overseas.png`,
      completeTitle: '技术支持（海外）',
      pcShow: true,
      notPCShow: false,
      onlyPhone: [
        {
          phone: '(+86) 0510-66758729',
          callPhone: '(+86) 0510-66758729',
          icon: `${process.env.ICON_PATH}/overseas.png`,
        },
      ],
    },
    {
      title: '投诉电话',
      icon: `${process.env.ICON_PATH}/president_office.png`,
      completeTitle: '总裁办24H投诉电话',
      pcShow: true,
      notPCShow: true,
      onlyPhone: [
        {
          name: '总裁办24H投诉电话',
          phone: '17312781526',
          callPhone: '17312781526',
          icon: `${process.env.ICON_PATH}/president_office.png`,
        },
      ],
    },
  ];

  const certContactInfos: IContactItem[] = [
    {
      title: '销售顾问',
      icon: `${process.env.ICON_PATH}/sales_consultant.png`,
      completeTitle: '销售顾问',
      pcShow: true,
      notPCShow: true,
      personInfo: enterpriseInfo?.saler ? enterpriseInfo?.saler : [],
      onlyPhone: [
        {
          phone: '400-811-8890 转 1',
          callPhone: '400-811-8890',
          icon: `${process.env.ICON_PATH}/sales_consultant.png`,
        },
      ],
    },
    {
      title: '技术支持',
      icon: `${process.env.ICON_PATH}/technical_support.png`,
      completeTitle: '技术支持',
      pcShow: true,
      notPCShow: true,
      personInfo: [
        {
          name: '大陆',
          phone: '400-811-8890 转 2',
        },
        {
          name: '海外',
          phone: '(+86) 0510-66758729',
        },
      ],
      onlyPhone: [
        {
          name: '大陆',
          phone: '400-811-8890 转 2',
          callPhone: '400-811-8890',
          icon: `${process.env.ICON_PATH}/technical_support.png`,
        },
        {
          name: '海外',
          phone: '(+86) 0510-66758729',
          callPhone: '(+86) 0510-66758729',
          icon: `${process.env.ICON_PATH}/overseas.png`,
        },
      ],
    },
    {
      title: '客户成功',
      icon: `${process.env.ICON_PATH}/customer_success.png`,
      completeTitle: '客户成功',
      pcShow: true,
      notPCShow: true,
      personInfo: enterpriseInfo?.successMan ? enterpriseInfo?.successMan : [],
    },
    {
      title: '投诉电话',
      icon: `${process.env.ICON_PATH}/president_office.png`,
      completeTitle: '总裁办24H投诉电话',
      pcShow: true,
      notPCShow: true,
      onlyPhone: [
        {
          name: '总裁办24H投诉电话',
          phone: '17312781526',
          callPhone: '17312781526',
          icon: `${process.env.ICON_PATH}/president_office.png`,
        },
      ],
    },
  ];

  return (
    <>
      <ComponentWrapper>
        <div className={styles.contact_fr_box}>
          <div className={styles.title}>联系帆软</div>
          <div
            className={styles.contact_fr_body}
            style={{ marginTop: '20px', height: '78px' }}
          >
            {(isCertDone ? certContactInfos : notCertContactInfos).map(
              (item, index) =>
                item.pcShow ? (
                  <div className={styles.contact_fr_body_item} key={index}>
                    <ContactItem contactItem={item} key={index} />
                    {index < 4 ? (
                      <div className={styles.contact_fr_box_divider}></div>
                    ) : null}
                  </div>
                ) : null,
            )}
          </div>
        </div>
      </ComponentWrapper>
      <ComponentWrapper mode="notPC">
        <div className={styles.contact_fr_box_mobile}>
          <div className={styles.title_mobile}>联系帆软</div>
          <div className={styles.contact_fr_items_mobile}>
            {(isCertDone ? certContactInfos : notCertContactInfos).map(
              (item, index) =>
                item.notPCShow ? (
                  <MobileContactItem contactItem={item} key={index} />
                ) : null,
            )}
          </div>
        </div>
      </ComponentWrapper>
    </>
  );
};

export default ContactFR;

export interface Person {
  name?: string;
  phone: string;
}
