import { Affix, Anchor } from 'antd';
import styles from './index.less';
import classNames from 'classnames';
import ResourceItem from './item';
import { Link, useModel, useRequest } from 'umi';
import { getAllProduct } from '@/utils/requests/requests_resource';
import { ReactNode, useEffect, useState } from 'react';
import { productManageItem } from './pageConfiguration';
import { ComponentWrapper } from '@digit-fe/digit-component';
import { Tabs } from 'antd-mobile';
import { isSuperAdmin } from '@/utils';

const { Link: AntdLink } = Anchor;

export default function IndexPage() {
  const [data, setData] = useState<productManageItem[]>([]);
  const [anchorData, setAnchorData] = useState<ReactNode[]>([]);
  const [activeKey, setActiveKey] = useState('');
  const { initialState } = useModel('@@initialState');

  const getAllProductRequest = useRequest(
    () => {
      return getAllProduct();
    },
    {
      manual: true,
      onSuccess: (res) => {
        Array.isArray(res) && res[0] && setActiveKey(res[0].id);
        Array.isArray(res) && res[0] && setData(res);
        Array.isArray(res) &&
          res[0] &&
          setAnchorData(
            //res.map((item: productManageItem) => ({
            //  key: item.id,
            //  href: `#${item.id}`,
            //  title: item.title,
            //})),
            res.map((item: productManageItem) => {
              return (
                <AntdLink
                  key={item.id}
                  href={`#${item.id}`}
                  title={item.title}
                />
              );
            }),
          );
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getAllProductRequest.run();
  }, []);

  return (
    <>
      <ComponentWrapper>
        <div className={classNames(styles.layout_items_start)}>
          <div style={{ width: 1056, marginRight: 24 }}>
            {data.map((item, index) => (
              <ResourceItem
                id={item.id}
                name={item.title}
                index={index}
                details={item}
              />
            ))}
          </div>
          <Affix offsetTop={103}>
            <div className={styles.wufan_anchor}>
              <img
                src={require('@/static/images/WuFan-Anchor.png')}
                className={styles.wufan_img}
              />
              <Anchor targetOffset={120}>{anchorData}</Anchor>
            </div>
            {isSuperAdmin(initialState?.uid) && (
              <Link
                to={`/resource/manage`}
                style={{
                  display: 'flex',
                  color: '#0082FC',
                  cursor: 'pointer',
                  marginTop: '12px',
                }}
              >
                <img
                  className={styles.setting_icon}
                  src={require('@/static/images/setting_outlined.png')}
                />
                产品资料配置
              </Link>
            )}
          </Affix>
        </div>
      </ComponentWrapper>
      <div style={{ width: '100%' }}>
        <ComponentWrapper mode="notPC">
          <div
            className={styles.mobile_resource_layout}
            style={{ width: '100%' }}
          >
            <Tabs
              activeLineMode="fixed"
              style={{
                marginTop: '-16px',
                marginBottom: '16px',
                marginLeft: '-16px',
                marginRight: '-16px',
                background: '#fff',
                height: '44px',
                position: 'sticky',
                top: '48px',
                zIndex: 1,
                '--fixed-active-line-width': '32px',
              }}
              activeKey={activeKey}
              onChange={(key) => {
                setActiveKey(key);
                document.getElementById(key)?.scrollIntoView();
              }}
            >
              {data.length > 0 &&
                data.map((item, index) => (
                  <Tabs.Tab title={item.title} key={item.id} />
                ))}
            </Tabs>
            {data.length > 0 &&
              data.map((item, index) => (
                <ResourceItem
                  id={item.id}
                  name={item.title}
                  index={index}
                  details={item}
                />
              ))}
          </div>
        </ComponentWrapper>
      </div>
    </>
  );
}
