import { requestGet, requestPut } from '@/utils/request';

// 获取公司信息
export function getEnterpriseInfo() {
  return requestGet('/service/api/company/info', {});
}

// 获取公司状态
export function getEnterpriseServiceState() {
  return requestGet('/service/api/company/state', {});
}

// 修改公司名称
export function updateEnterpriseName(params: string) {
  return requestPut(`/service/api/company/rename?name=${params}`, {});
}

// 催办
export function urgeAudit() {
  return requestGet(`/service/api/push/cooperate/verify`, {});
}

// 获取企业实名认证
export function getServiceAuthInterface() {
  return requestGet(`/service/api/auth`, {});
}
