import React from 'react';

type Props = {};

const NoAuth: React.FC<Props> = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '90px' }}>
      <img
        style={{ width: '200px', height: '200px' }}
        src={require('@/static/images/no_auth.png')}
      />
      <div
        style={{
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#617288',
          lineHeight: '24px',
          marginTop: '24px',
        }}
      >
        抱歉，您无权访问此页面
      </div>
      <div
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#b1bac5',
          lineHeight: '22px',
          marginTop: '16px',
        }}
      >
        该功能仅对授权用户开放，如需授权请联系groots
      </div>
    </div>
  );
};

export default NoAuth;
