import React, { useEffect, useState } from 'react';
import styles from './index.less';
import { Button, message } from 'antd';
import AddOrEditProductModal from './addOrEditProductModal';
import ProductResourceDetails from './detailsTable';
import NotAdded from '@/components/notAdded/notAdded';
import { useRequest, useModel } from 'umi';
import {
  getProductManageRecords,
  sortProductManageRecords,
} from '@/utils/requests/requests_resource';
import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table } from 'antd';
import { isSuperAdmin } from '@/utils';

type Props = {};

export interface productManageItem {
  key: string;
  brief: string;
  enabled: boolean;
  id: string;
  index: number;
  logo: string;
  title: string;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && { ...transform, scaleY: 1 },
    )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const ProductResourceConfiguration: React.FC<Props> = () => {
  const [dataSource, setDataSource] = useState<productManageItem[]>([]);
  const [rowName, setRowName] = useState('');
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const { initialState } = useModel('@@initialState');
  const getProductManageRecordsRequest = useRequest(
    () => {
      return getProductManageRecords();
    },
    {
      manual: true,
      onSuccess: (res) => {
        window.location.href.indexOf('?id=') < 0 && setRowName(res[0].id);
        setDataSource(
          res.map((item: productManageItem) =>
            Object.assign(item, { key: item.id }),
          ),
        );
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getProductManageRecordsRequest.run();
  }, [refresh]);

  const sortProductManageRecordsRequest = useRequest(
    (value) => {
      return sortProductManageRecords(JSON.stringify(value));
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  useEffect(() => {
    window.location.href.indexOf('?id=') > -1 &&
      setRowName(
        window.location.href.substring(
          window.location.href.indexOf('?id=') + 4,
        ),
      );
  }, []);

  const columns = [
    {
      title: 'sort',
      dataIndex: 'sort',
      width: 50,
    },
    {
      title: 'logo',
      dataIndex: 'logo',
      width: 50,
      className: 'logo-drag-visible',
      render: (value: string) => (
        <img src={value} style={{ width: 32, height: 32, borderRadius: 4 }} />
      ),
    },
    {
      title: 'title',
      dataIndex: 'title',
      width: 120,
      className: 'title-drag-visible',
    },
    {
      title: 'enabled',
      dataIndex: 'enabled',
      width: 80,
      className: 'drag-visible',
      render: (value: boolean) => (
        <span style={{ color: '#C5C7CE' }}>{value ? '' : '停用中'}</span>
      ),
    },
  ];

  const unauthColumns = [
    {
      title: '',
      width: 24,
    },
    {
      title: 'logo',
      dataIndex: 'logo',
      width: 50,
      className: 'logo-drag-visible',
      render: (value: string) => (
        <img src={value} style={{ width: 32, height: 32, borderRadius: 4 }} />
      ),
    },
    {
      title: 'title',
      dataIndex: 'title',
      width: 145,
      className: 'title-drag-visible',
    },
    {
      title: 'enabled',
      dataIndex: 'enabled',
      width: 80,
      className: 'drag-visible',
      render: (value: boolean) => (
        <span style={{ color: '#C5C7CE' }}>{value ? '' : '停用中'}</span>
      ),
    },
  ];

  return (
    <div className={styles.configure_permissions_div}>
      <div
        className={styles.configure_permissions_title}
        style={{ fontSize: '18px' }}
      >
        产品资料配置
      </div>
      <div className={styles.configure_permissions_body_flex}>
        <div className={styles.configure_permissions_body_flex_left}>
          {isSuperAdmin(initialState?.uid) ? (
            <div style={{ padding: '24px' }}>
              <Button
                type="primary"
                onClick={() => setAddProductModalOpen(true)}
              >
                添加产品
              </Button>
              <AddOrEditProductModal
                addProductModalOpen={addProductModalOpen}
                setAddProductModalOpen={setAddProductModalOpen}
                num={dataSource.length}
                refresh={refresh}
                setRefresh={setRefresh}
              />
              <span style={{ color: '#C5C7CE', marginLeft: '10px' }}>
                鼠标上下拖拽可以排序
              </span>
            </div>
          ) : (
            <div style={{ padding: '24px', color: '#C5C7CE' }}>
              仅展示有权限配置的产品，如需授权请联系groots
            </div>
          )}
          <div style={{ textAlign: 'center' }}>
            {dataSource.length > 0 ? (
              <DndContext
                onDragEnd={({ active, over }: DragEndEvent) => {
                  let newData: productManageItem[] = [];
                  setDataSource((previous: productManageItem[]) => {
                    const activeIndex = previous.findIndex(
                      (i) => i.key === active.id,
                    );
                    const overIndex = previous.findIndex(
                      (i) => i.key === over?.id,
                    );
                    newData = arrayMove(previous, activeIndex, overIndex);

                    return newData;
                  });
                  sortProductManageRecordsRequest.run(
                    newData.map((item: productManageItem, index: number) => ({
                      id: item.id,
                      index: index,
                    })),
                  );
                }}
              >
                <SortableContext
                  items={dataSource.map((i) => i.key)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    components={{
                      body: {
                        row: Row,
                      },
                    }}
                    showHeader={false}
                    rowKey="key"
                    onRow={(record) => {
                      return {
                        onClick: (event) => {
                          setRowName(record.id);
                        },
                      };
                    }}
                    rowClassName={(record: any) => {
                      return record.id === rowName ? 'selected-row' : '';
                    }}
                    pagination={false}
                    columns={
                      isSuperAdmin(initialState?.uid) ? columns : unauthColumns
                    }
                    dataSource={dataSource}
                  />
                </SortableContext>
              </DndContext>
            ) : (
              <NotAdded />
            )}
          </div>
        </div>
        <div className={styles.configure_permissions_body_flex_right}>
          <ProductResourceDetails
            id={rowName}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductResourceConfiguration;
