import { requestDelete, requestGet, requestPost } from '@/utils/request';

// 获取个人服务或内部服务数据
export function apiGetIndexData(type: 'PERSONAL' | 'INTERNAL') {
  return requestGet('/fcoin/api/myfr/config', { type });
}

// 修改个人服务或内部服务数据
export function apiChangeIndexData(
  type: 'PERSONAL' | 'INTERNAL',
  params: {
    id: string;
    layout?: string;
    welcomeContent?: string;
  },
) {
  return requestPost(`/fcoin/api/myfr/config?type=${type}`, params);
}

// 个人服务页面获取最新推荐列表
export function getRecommendationsList(params: {
  current: number;
  size: number;
}) {
  return requestGet('/api/recommendations/list', params);
}

// 个人服务页面获取最新推荐列表的标签
export function getRecommendationListTags() {
  return requestGet('/api/recommendations/product/tags', {});
}

// 个人服务页面获取展示数据
export function getPersonalData() {
  return requestGet('/api/personal/cardItemList', {});
}

// 个人服务页面右侧获取广告位
export function getRecommendationCardsList() {
  return requestGet(
    'https://api.shequ.fanruan.com/v1/edu/studyclass/courselist/',
    {},
  );
}

// 获取卡片数据
export function apiGetIndexCard(configType: 'PERSONAL' | 'INTERNAL') {
  return requestGet('/fcoin/api/myfr/card', { configType });
}

// 修改卡片数据
export function apiChangeIndexCard(
  configType: 'PERSONAL' | 'INTERNAL',
  params: any,
) {
  return requestPost(`/fcoin/api/myfr/card?configType=${configType}`, {
    ...params,
  });
}

// 删除某个卡片
export function apiDeleteCardById(id: string) {
  return requestDelete(`/fcoin/api/myfr/card/${id}`, {});
}
