import React, { useState } from 'react';
import {
  EEnterpriseAuth,
  EEnterpriseStatus,
  EEnterpriseUserIdentify,
  IEnterpriseInfo,
} from '@/types/enterpriseService';
import { useRequest } from '@@/plugin-request/request';
import {
  getEnterpriseInfo,
  getEnterpriseServiceState,
  getServiceAuthInterface,
} from '@/services/enterpriseService';

export default function useEnterpriseModel() {
  // 企业状态
  const [enterpriseStatus, setEnterpriseStatus] = useState<EEnterpriseStatus>();

  // 用户角色
  const [role, setRole] = useState<EEnterpriseUserIdentify>(
    EEnterpriseUserIdentify.MEMBER,
  );

  // 用户提交工单权限
  const [ticketAuth, setTicketAuth] = useState<boolean>(false);

  // 判断企业权限
  const [enterpriseAuth, setEnterpriseAuth] = useState<EEnterpriseAuth>(
    EEnterpriseAuth.UNAVAILABLE,
  );

  // 企业信息
  const [enterpriseInfo, setEnterpriseInfo] = useState<IEnterpriseInfo>();

  // 企业名称
  const [enterpriseName, setEnterpriseName] = useState('');

  // 企业实名认证状态
  const [enterpriseRealNameAuth, setEnterpriseRealNameAuth] =
    useState<boolean>(true);

  // 获取企业状态
  const {
    run: getEnterpriseServiceStateRun,
    loading: getEnterpriseServiceStateRunning,
  } = useRequest(
    () => {
      return getEnterpriseServiceState();
    },
    {
      manual: true,
      onSuccess: (res) => {
        setEnterpriseStatus(res);
        setEnterpriseAuth(checkEnterpriseAuth(res));
      },
    },
  );

  // 获取企业信息
  const { run: getEnterpriseInfoRun, loading: getEnterpriseInfoRunning } =
    useRequest(
      () => {
        return getEnterpriseInfo();
      },
      {
        manual: true,
        onSuccess: (res) => {
          if (res.role !== undefined) {
            setRole(res.role);
          }
          setTicketAuth(res.ticketAuthCompany);
          setEnterpriseInfo({
            enterpriseName: res.comName,
            expiredTime: res.certificateEndTime,
            logo: res.comHead,
            saler: res.saler,
            successMan: res.successMan,
          });
        },
      },
    );

  // 获取企业实名认证信息等
  const { run: getServiceAuthInterfaceRun } = useRequest(
    () => {
      return getServiceAuthInterface();
    },
    {
      manual: true,
      onSuccess: (res) => {
        if (res && res.realName !== undefined) {
          setEnterpriseRealNameAuth(res.realName);
        }
      },
    },
  );

  // 更新企业信息
  const updateEnterpriseInfo = (params: any) => {
    let data = Object.assign({}, enterpriseInfo, params);
    setEnterpriseInfo(data);
  };

  return {
    getEnterpriseInfoRun,
    getEnterpriseInfoRunning,
    enterpriseStatus,
    role,
    enterpriseAuth,
    ticketAuth,
    enterpriseInfo,
    enterpriseName,
    updateEnterpriseInfo,
    getEnterpriseServiceStateRun,
    getEnterpriseServiceStateRunning,
    enterpriseRealNameAuth,
    getServiceAuthInterfaceRun,
  };
}

// 判断企业权限
const checkEnterpriseAuth = (
  enterpriseStatus: EEnterpriseStatus | undefined,
) => {
  // 不可用技术支持服务
  const unavailableStatus = [
    EEnterpriseStatus.NOT_JOIN,
    EEnterpriseStatus.NOT_CERT,
    EEnterpriseStatus.UNDER_REVIEW,
    EEnterpriseStatus.NOT_PASS,
  ];

  // 可用技术支持服务
  const availableStatus = [
    EEnterpriseStatus.BASE_SERVICE_IN_EFFECT,
    EEnterpriseStatus.FORMAL_SERVICE_IN_EFFECT,
    EEnterpriseStatus.PREMIUM_SERVICE_IN_EFFECT,
    EEnterpriseStatus.TEMPORARY_SERVICE_IN_EFFECT,
  ];

  // 有限使用技术支持服务
  const limitedStatus = [
    EEnterpriseStatus.BASE_SERVICE_EXPIRED,
    EEnterpriseStatus.FORMAL_SERVICE_EXPIRED,
    EEnterpriseStatus.PREMIUM_SERVICE_EXPIRED,
    EEnterpriseStatus.TEMPORARY_SERVICE_EXPIRED,
    EEnterpriseStatus.CANNOT_GET_SERVICE_STATUS,
    EEnterpriseStatus.CANNOT_GET_VALIDITY_PERIOD,
    EEnterpriseStatus.NOT_COOPERATE,
  ];

  if (enterpriseStatus !== undefined) {
    if (unavailableStatus.includes(enterpriseStatus)) {
      return EEnterpriseAuth.UNAVAILABLE;
    }

    if (availableStatus.includes(enterpriseStatus)) {
      return EEnterpriseAuth.AVAILABLE;
    }

    if (limitedStatus.includes(enterpriseStatus)) {
      return EEnterpriseAuth.LIMITED;
    }

    return EEnterpriseAuth.UNAVAILABLE;
  } else {
    return EEnterpriseAuth.UNAVAILABLE;
  }
};
