import React, { useState } from 'react';
import styles from '../index.less';
import {
  Button,
  Col,
  Row,
  Input,
  Modal,
  Form,
  Switch,
  Upload,
  Tooltip,
  message,
} from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link, useRequest } from 'umi';
import ContentTypeManageTable from './table';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import {
  calFileUploadParams,
  getCoverBase64,
  getFileNameAndType,
  randomStr,
} from '@/utils';
import axios from 'axios';
import { addOrEditContentType } from '@/utils/requests/requests_push';
import { submitFile } from '@/utils/requests/requests_utils';

type Props = {};

const ContentTypeManage: React.FC<Props> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [keyword, setKeyword] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [enabled, setEnabled] = useState(true);
  const [pic, setPic] = useState<string | undefined>();
  const [refresh, setRefresh] = useState(0);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [coverImage, setCoverImage] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const submitFileRequest = useRequest(
    (fileName: string, contentType: string) => {
      return submitFile({ path: 'myfr', fileName, contentType });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  const addOrEditContentTypeRequest = useRequest(
    () => {
      return addOrEditContentType(
        JSON.stringify({
          name,
          pic,
          enabled,
        }),
      );
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  return (
    <div className={styles.configure_permissions_div}>
      <div className={styles.configure_permissions_title}>
        <Row>
          <Col span={12} style={{ fontSize: '18px' }}>
            <Link to="/news/add" style={{ color: '#333' }}>
              <LeftOutlined />
            </Link>{' '}
            内容类型管理
          </Col>
        </Row>
      </div>
      <div className={styles.configure_permissions_body}>
        <Row>
          <Col span={12}>
            <Input.Search
              placeholder="内容类型名称"
              allowClear
              style={{ width: 360 }}
              value={keyword}
              onSearch={(value) => setKeyword(value)}
              onPressEnter={(event: React.KeyboardEvent<HTMLInputElement>) => {
                setKeyword((event.target as HTMLInputElement).value);
              }}
            />
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
              新增
            </Button>
            <Modal
              title="新增内容类型"
              open={isModalOpen}
              okText="确认"
              cancelText="取消"
              width={664}
              onOk={() => form.submit()}
              onCancel={() => setIsModalOpen(false)}
            >
              <Form
                name="basic"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                initialValues={{ remember: true }}
                onFinish={() => {
                  addOrEditContentTypeRequest.run().then(() => {
                    setIsModalOpen(false);
                    setRefresh(refresh + 1);
                    message.success('新增成功');
                  });
                }}
                autoComplete="off"
              >
                <Form.Item
                  label="类型名称"
                  name="label"
                  rules={[{ required: true, message: '请输入类型名称' }]}
                >
                  <Input
                    placeholder="请输入..."
                    onChange={(event) => setName(event.target.value)}
                  />
                </Form.Item>
                <Form.Item label="启用状态" valuePropName="checked">
                  <Switch
                    checked={enabled}
                    onChange={(checked: boolean) => setEnabled(checked)}
                  />
                </Form.Item>
                <Form.Item
                  label="默认图片"
                  name={'picture'}
                  rules={[{ required: true, message: '请上传图片' }]}
                >
                  <div style={{ color: '#C5C7CE', lineHeight: '27px' }}>
                    图片格式为.png，尺寸为80*80px，大小不超过1MB
                  </div>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    accept={'.png'}
                    fileList={fileList}
                    showUploadList={false}
                    customRequest={(info) => {
                      let fileInfo = getFileNameAndType(
                        (info.file as RcFile).name,
                      );
                      let uploadParams = calFileUploadParams(
                        fileInfo.type,
                        'myfr',
                      );
                      submitFileRequest
                        .run(uploadParams.fileName, (info.file as RcFile).type)
                        .then((res) => {
                          axios.request({
                            method: 'PUT',
                            url: res.signedUrl,
                            withCredentials: false,
                            headers:
                              Object.assign(res.actualSignedRequestHeaders, {
                                'Content-Disposition': `attachment; filename=${encodeURIComponent(
                                  (info.file as RcFile).name,
                                )}`,
                              }) || {},
                            data: info.file,
                          });
                        })
                        .then(() => {
                          let picture = `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`;
                          setPic(picture);
                          form.setFieldsValue({ picture: picture });
                          getCoverBase64(info.file, (imageUrl: any) => {
                            setLoading(false);
                            setCoverImage(imageUrl);
                          });
                          setFileList([
                            {
                              uid: randomStr(7),
                              name: (info.file as RcFile)?.name,
                              url: picture,
                              status: 'done',
                            },
                          ]);
                        });
                    }}
                    beforeUpload={(file: RcFile) => {
                      const isJpgOrPng = file.type === 'image/png';
                      if (!isJpgOrPng) {
                        message.error('只支持.png格式!');
                      }
                      const isLt2M = file.size / 1024 / 1024 < 1;
                      if (!isLt2M) {
                        message.error('大小不超过1MB');
                      }
                      return isJpgOrPng && isLt2M;
                    }}
                    onRemove={() => {
                      setFileList([]);
                      form.setFieldsValue({
                        picture: undefined,
                      });
                    }}
                  >
                    {coverImage ? (
                      <Tooltip title="重新上传">
                        <img
                          src={coverImage}
                          alt="avatar"
                          style={{ width: '100%' }}
                        />
                      </Tooltip>
                    ) : (
                      <div>
                        <div style={{ marginTop: 8 }}>PIC</div>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
              </Form>
            </Modal>
          </Col>
        </Row>
        <ContentTypeManageTable keyword={keyword} refresh={refresh} />
      </div>
    </div>
  );
};

export default ContentTypeManage;
