import React from 'react';

type TVisibleComponent = {
  children: React.ReactNode;
  visible?: boolean;
};

const VisibleComponent: React.FC<TVisibleComponent> = ({
  children,
  visible = true,
}) => {
  return <>{visible && children}</>;
};
export default VisibleComponent;
