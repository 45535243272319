import React, { ReactNode, useRef } from 'react';
import styles from './index.less';
import { Button, Space } from 'antd';
import { EEnterpriseStatus } from '@/types/enterpriseService';
import { useModel } from '@@/plugin-model/useModel';
import CustomServiceButton from '@/components/customServiceButton';
import { hasManagePermission } from '@/utils/enterprise';
import ContactModal, {
  IContactPersonItem,
} from '@/pages/enterpriseService/components/contactFR/contactModal';
import { useRequest } from '@@/plugin-request/request';
import { postBuried } from '@/utils/requests/requests_utils';
import { useMediaQuery } from 'react-responsive';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface IHeaderInfo {
  icon: ReactNode;
  statusText: string;
  color: 'yellow' | 'green' | 'grey' | 'red' | 'black';
  extraButton?: ReactNode | string;
  onClick?: () => void;
}

const iconPath = process.env.ICON_PATH;

type TIHProps = {};

const InfoHeader: React.FC<TIHProps> = () => {
  const { enterpriseStatus, role, enterpriseInfo } = useModel(
    'useEnterpriseModel',
    (model) => ({
      enterpriseStatus: model.enterpriseStatus,
      role: model.role,
      enterpriseInfo: model.enterpriseInfo,
    }),
  );

  const isNotPC = useMediaQuery({ query: '(max-width: 1199.99px)' });

  const contactRef = useRef(null);

  // 字体颜色
  const textColor = {
    yellow: '#ffa800',
    green: '#17b082',
    grey: '#7c8eb6',
    red: '#f53f3f',
    black: '#323232',
  };

  // 企业服务头部埋点
  const { run: postBuriedRun } = useRequest(
    (menu: string) => {
      return postBuried({
        portal: '服务平台',
        classification: '技术支持服务',
        menu,
      });
    },
    {
      manual: true,
    },
  );

  const hasConsultants: IContactPersonItem[] = [
    {
      icon: `${process.env.ICON_PATH}/sales_consultant.png`,
      tag: '专属销售顾问',
      person: enterpriseInfo?.saler,
    },
    {
      icon: `${process.env.ICON_PATH}/phone.png`,
      tag: '售前咨询电话',
      person: [{ phone: '400-811-8890 转 1' }],
    },
  ];

  const noConsultant: IContactPersonItem[] = [
    {
      icon: `${process.env.ICON_PATH}/phone.png`,
      tag: '售前咨询电话',
      person: [{ phone: '400-811-8890 转 1' }],
    },
  ];

  // 权益说明链接（不区分软件公司版）
  const equityDescription = `${process.env.BBS_URL}/thread-143251-1-1.html`;

  // 企业状态展示
  const enterpriseStatusShow: { [key in EEnterpriseStatus]: IHeaderInfo } = {
    [EEnterpriseStatus.NOT_JOIN]: {
      icon: 'no_service.png',
      statusText: '加入合作企业解锁全部服务',
      color: 'grey',
    },
    [EEnterpriseStatus.NOT_CERT]: {
      icon: 'no_service.png',
      statusText: hasManagePermission(role)
        ? '合作认证后解锁全部服务'
        : '企业未合作认证，需管理员去认证，或退出企业自行认证',
      color: 'grey',
    },
    [EEnterpriseStatus.UNDER_REVIEW]: {
      icon: 'cert_under_review.png',
      statusText: '合作认证审核中',
      color: 'yellow',
    },
    [EEnterpriseStatus.NOT_PASS]: {
      icon: hasManagePermission(role) ? 'cert_not_pass.png' : 'no_service.png',
      statusText: hasManagePermission(role)
        ? '未通过合作认证'
        : '企业未合作认证，需管理员去认证，或退出企业自行认证',
      color: hasManagePermission(role) ? 'red' : 'grey',
    },
    [EEnterpriseStatus.BASE_SERVICE_IN_EFFECT]: {
      icon: 'service_in_effect.png',
      statusText: '基础服务生效中',
      color: 'green',
      extraButton: isNotPC ? <QuestionCircleOutlined /> : '权益说明',
      onClick: () => {
        window.open(equityDescription);
        postBuriedRun('权益说明-最终客户');
      },
    },
    [EEnterpriseStatus.FORMAL_SERVICE_IN_EFFECT]: {
      icon: 'service_in_effect.png',
      statusText: '正式服务生效中',
      color: 'green',
      extraButton: isNotPC ? <QuestionCircleOutlined /> : '权益说明',
      onClick: () => {
        window.open(equityDescription);
        postBuriedRun('权益说明-软件公司');
      },
    },
    [EEnterpriseStatus.PREMIUM_SERVICE_IN_EFFECT]: {
      icon: 'advanced_service.png',
      statusText: '高级服务生效中',
      color: 'black',
      extraButton: isNotPC ? <QuestionCircleOutlined /> : '权益说明',
      onClick: () => {
        window.open(equityDescription);
        postBuriedRun('权益说明-最终客户');
      },
    },
    [EEnterpriseStatus.TEMPORARY_SERVICE_IN_EFFECT]: {
      icon: 'service_in_effect.png',
      statusText: '临时服务生效中',
      color: 'green',
      extraButton: isNotPC ? <QuestionCircleOutlined /> : '权益说明',
      onClick: () => {
        window.open(equityDescription);
        postBuriedRun('权益说明-最终客户');
      },
    },
    [EEnterpriseStatus.BASE_SERVICE_EXPIRED]: {
      icon: 'service_expired.png',
      statusText: '支持服务已过期',
      color: 'yellow',
      extraButton: isNotPC ? <QuestionCircleOutlined /> : '权益说明',
      onClick: () => {
        window.open(equityDescription);
        postBuriedRun('权益说明-最终客户');
      },
    },
    [EEnterpriseStatus.FORMAL_SERVICE_EXPIRED]: {
      icon: 'service_expired.png',
      statusText: '支持服务已过期',
      color: 'yellow',
      extraButton: isNotPC ? <QuestionCircleOutlined /> : '权益说明',
      onClick: () => {
        window.open(equityDescription);
        postBuriedRun('权益说明-软件公司');
      },
    },
    [EEnterpriseStatus.PREMIUM_SERVICE_EXPIRED]: {
      icon: 'service_expired.png',
      statusText: '支持服务已过期',
      color: 'yellow',
      extraButton: isNotPC ? <QuestionCircleOutlined /> : '权益说明',
      onClick: () => {
        window.open(equityDescription);
        postBuriedRun('权益说明-最终客户');
      },
    },
    [EEnterpriseStatus.TEMPORARY_SERVICE_EXPIRED]: {
      icon: 'service_expired.png',
      statusText: '临时服务已过期',
      color: 'yellow',
      extraButton: isNotPC ? <QuestionCircleOutlined /> : '权益说明',
      onClick: () => {
        window.open(equityDescription);
        postBuriedRun('权益说明-最终客户');
      },
    },
    [EEnterpriseStatus.CANNOT_GET_SERVICE_STATUS]: {
      icon: 'cert_not_pass.png',
      statusText: '无法获取服务状态',
      color: 'red',
      extraButton: (
        <CustomServiceButton
          buriedPortal={'服务平台'}
          buriedClassification={'技术支持服务'}
        />
      ),
    },
    [EEnterpriseStatus.CANNOT_GET_VALIDITY_PERIOD]: {
      icon: 'cert_not_pass.png',
      statusText: '无法获取服务有效期',
      color: 'red',
      extraButton: (
        <CustomServiceButton
          buriedPortal={'服务平台'}
          buriedClassification={'技术支持服务'}
        />
      ),
    },
    [EEnterpriseStatus.NOT_COOPERATE]: {
      icon: 'no_service.png',
      statusText: '非合作企业',
      color: 'grey',
      extraButton: (
        <Space className={styles.layout_vertical_center}>
          <span>无法获取服务</span>
          <Button
            type={'link'}
            style={{ padding: 0 }}
            onClick={() => {
              // @ts-ignore
              contactRef.current.openModal();
              postBuriedRun('联系销售顾问');
            }}
          >
            联系销售顾问
          </Button>
        </Space>
      ),
    },
  };

  return enterpriseStatus !== undefined ? (
    <div className={styles.header}>
      <Space size={isNotPC ? 7 : 16} className={styles.left_part}>
        <Space size={8} className={styles.layout_vertical_center}>
          <div className={styles.status_icon}>
            <img
              src={`${iconPath}/${enterpriseStatusShow[enterpriseStatus].icon}`}
            />
          </div>
          <div
            className={styles.status_text}
            style={{
              color: textColor[enterpriseStatusShow[enterpriseStatus].color],
            }}
          >
            {enterpriseStatusShow[enterpriseStatus].statusText}
          </div>
        </Space>
        {enterpriseStatusShow[enterpriseStatus].onClick ? (
          <div
            className={styles.extra_content}
            onClick={enterpriseStatusShow[enterpriseStatus].onClick}
          >
            {enterpriseStatusShow[enterpriseStatus].extraButton}
          </div>
        ) : (
          enterpriseStatusShow[enterpriseStatus].extraButton
        )}
      </Space>
      <div className={styles.right_part}>
        {enterpriseInfo?.expiredTime && `有效期至${enterpriseInfo.expiredTime}`}
        <ContactModal
          ref={contactRef}
          list={
            enterpriseInfo?.saler && enterpriseInfo?.saler.length > 0
              ? hasConsultants
              : noConsultant
          }
        />
      </div>
    </div>
  ) : null;
};

export default InfoHeader;
