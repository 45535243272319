import React, { forwardRef, useImperativeHandle, useState } from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Form, message, Space, Table } from 'antd';
import EditableCell from './editCell';
import DragRow from '@/components/editDragTable/editRow';
import { TDragCardItemData, TEditDragTableRef } from '@/types/common/index';
import { ECardItemType } from '@/enum/card';

export type Item = TDragCardItemData & {
  [key: string]: any;
};

type TEditDragTableProps = {
  initData?: Item[];
  onDelete: () => void;
  itemLimit: number;
};

const EditDragTable = forwardRef<TEditDragTableRef, TEditDragTableProps>(
  (props, ref) => {
    const { initData = [], onDelete, itemLimit } = props;
    const [form] = Form.useForm();
    const [cache, setCache] = useState<Item>();
    const [data, setData] = useState<Item[]>(initData);
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record: Item) => record.id === editingKey;

    const edit = (record: Item & { id: React.Key }) => {
      form.setFieldsValue({ ...record });
      setCache({ ...record });
      setEditingKey(record.id);
    };

    const cancel = (id: string) => {
      const idx = data.findIndex((item) => item.id === id);
      if (idx > -1) {
        const item = data[idx];
        if (item.isNew) {
          setData((prevState) => {
            prevState.splice(idx, 1);
            return [...prevState];
          });
          onDelete();
        }
        if (cache) {
          setData((prevState) => {
            prevState.splice(idx, 1, cache);
            return [...prevState];
          });
        }
      }
      setEditingKey('');
    };

    const save = async (id: React.Key, callback?: (res: any) => void) => {
      try {
        const row = (await form.validateFields()) as Item;
        const newData = [...data];
        const index = newData.findIndex((item) => id === item.id);
        if (index > -1) {
          const item = newData[index];
          delete item.isNew;
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          callback?.(newData);
          setData(newData);
          setEditingKey('');
        } else {
          newData.push(row);
          setData(newData);
          setEditingKey('');
        }
        form.setFieldsValue({
          id: '',
          index: 0,
          type: undefined,
          title: '',
          url: '',
        });
      } catch (errInfo) {
        console.log('Validate Failed:', errInfo);
      }
    };
    const deleteRow = (id: string) => {
      if (data.length > 1) {
        const idx = data.findIndex((item) => item.id === id);
        if (idx > -1) {
          setData((prevState) => {
            prevState.splice(idx, 1, { ...data[idx], delete: true });
            return [...prevState];
          });
          onDelete();
        }
      } else {
        message.warning('卡片中至少要有一个内容');
      }
    };
    const columns = [
      {
        key: 'sort',
        width: '10%',
      },
      {
        title: '标题',
        dataIndex: 'title',
        editable: true,
        width: '25%',
        inputType: 'input',
      },
      {
        title: '类型',
        dataIndex: 'type',
        editable: true,
        width: '10%',
        inputType: 'select',
        render: (_: any, record: { type: ECardItemType }) => {
          return (
            <span>{record.type === ECardItemType.url ? '链接' : '文件'}</span>
          );
        },
      },
      {
        title: '移动端展示',
        dataIndex: 'mobileShow',
        editable: true,
        width: '10%',
        inputType: 'switch',
        render: (_: any, record: { mobileShow: boolean }) => {
          return record.mobileShow ? '展示' : '不展示';
        },
      },
      {
        title: 'URL',
        dataIndex: 'url',
        editable: true,
        width: '25%',
        inputType: 'fileAndUrl',
        render: (_: any, record: { url: string }) => {
          return (
            <a href={record.url} target="_blank">
              {record.url}
            </a>
          );
        },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        width: '10%',
        render: (_: any, record: Item) => {
          const editable = isEditing(record);
          return editable ? (
            <Space>
              <div onClick={() => save(record.id)} style={{ marginRight: 8 }}>
                <a>保存</a>
              </div>
              <div
                title="Sure to cancel?"
                onClick={() => {
                  cancel(record.id);
                }}
              >
                <a>取消</a>
              </div>
            </Space>
          ) : (
            <Space>
              <div onClick={() => edit(record)}>
                <a>编辑</a>
              </div>
              <div onClick={() => deleteRow(record.id)}>
                <a style={{ color: 'red' }}>删除</a>
              </div>
            </Space>
          );
        },
      },
    ];
    const mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: Item) => {
          const deepCopyRecord = JSON.parse(JSON.stringify(record));
          return {
            record,
            onChange: (res: any) => {
              if (res === ECardItemType.file) {
                record.type = ECardItemType.file;
              } else {
                record.type = ECardItemType.url;
              }
            },
            inputType: col.inputType,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          };
        },
      };
    });

    const onDragEnd = ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        setData((previous) => {
          const activeIndex = previous.findIndex((i) => i.id === active.id);
          const overIndex = previous.findIndex((i) => i.id === over?.id);
          return arrayMove(previous, activeIndex, overIndex);
        });
      }
    };
    useImperativeHandle(ref, () => {
      return {
        addRow: async () => {
          await form.validateFields();
          if (editingKey) {
            message.warning('目前正有数据处于编辑状态，请先保存或取消');
          } else {
            form.setFieldsValue({
              id: '',
              index: 0,
              type: undefined,
              title: '',
              url: '',
              isNew: true,
            });
            const newId = String(new Date().valueOf());
            setData((prev) => {
              prev.push({
                id: newId,
                index: 0,
                type: ECardItemType.url,
                title: '',
                url: '',
                isNew: true,
                mobileShow: true,
              });
              return [...prev];
            });
            setEditingKey(newId);
          }
        },
        getData: async () => {
          try {
            let dataSource = data;
            // 如果还有行处于编辑状态，先保存
            if (editingKey) {
              await save(editingKey, (res) => {
                dataSource = res;
              });
            }
            await form.validateFields();
            return dataSource.filter((item) => !item.delete);
          } catch (e) {
            return undefined;
          }
        },
      };
    });

    return (
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={data.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Form form={form} component={false}>
            <Table
              pagination={false}
              components={{
                body: {
                  row: DragRow,
                  cell: EditableCell,
                },
              }}
              rowKey="id"
              onRow={(_, index) => {
                const attr = {
                  index,
                  isInHidden: (index as number) > itemLimit - 1,
                  columns,
                  record: _,
                };
                return attr as unknown as React.HTMLAttributes<any>;
              }}
              columns={mergedColumns}
              dataSource={data.filter((item) => !item.delete)}
            />
          </Form>
        </SortableContext>
      </DndContext>
    );
  },
);
export default EditDragTable;
