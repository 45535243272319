import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useRequest } from 'umi';
import {
  addOrEditContentType,
  addOrEditLabel,
} from '@/utils/requests/requests_push';

type Props = {
  type: string;
  name: string;
  id: string;
  enabled: boolean;
  pic?: string;
};

const LabelInput: React.FC<Props> = ({ type, name, id, enabled, pic }) => {
  const [inputOpen, setInputOpen] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(name);
  }, [name]);

  const addOrEditLabelRequest = useRequest(
    () => {
      return addOrEditLabel(
        JSON.stringify({
          name: value,
          id,
          enabled,
        }),
      );
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  const addOrEditContentTypeRequest = useRequest(
    () => {
      return addOrEditContentType(
        JSON.stringify({
          name: value,
          id,
          enabled,
          pic,
        }),
      );
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  return (
    <div>
      {inputOpen ? (
        <>
          <Input
            value={value}
            style={{ width: '400px' }}
            onChange={(event) => {
              setValue(event.target.value);
            }}
          />
          <Button
            type="link"
            icon={<CheckOutlined />}
            onClick={() => {
              type == 'label' &&
                addOrEditLabelRequest.run().then(() => {
                  setInputOpen(false);
                  message.success('修改成功');
                });
              type == 'contentType' &&
                addOrEditContentTypeRequest.run().then(() => {
                  setInputOpen(false);
                  message.success('修改成功');
                });
            }}
          />
          <Button
            danger
            type="text"
            icon={<CloseOutlined />}
            onClick={() => {
              setInputOpen(false);
              setValue(name);
            }}
          />
        </>
      ) : (
        <>
          <span>{value}</span>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => setInputOpen(true)}
          />
        </>
      )}
    </div>
  );
};

export default LabelInput;
