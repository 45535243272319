import React from 'react';
import { useModel } from '@@/plugin-model/useModel';
import { EEnterpriseStatus, EUrgeResult } from '@/types/enterpriseService';
import { Button, message, Modal, Space } from 'antd';
import { useRequest } from '@@/plugin-request/request';
import { urgeAudit } from '@/services/enterpriseService';
import { useMediaQuery } from 'react-responsive';
import { Dialog } from 'antd-mobile';
import { postBuried } from '@/utils/requests/requests_utils';
import styles from './index.less';
import { hasManagePermission } from '@/utils/enterprise';

type TBABProps = {
  identify?: 'developer' | 'leader';
};

const ID_URL = process.env.ID_URL;

const BootAuthButton: React.FC<TBABProps> = ({ identify = 'developer' }) => {
  const { enterpriseStatus, role } = useModel(
    'useEnterpriseModel',
    (model) => ({
      enterpriseStatus: model.enterpriseStatus,
      role: model.role,
    }),
  );
  const isDeveloper = identify === 'developer';

  const isNotPC = useMediaQuery({ query: '(max-width: 1199.99px)' });

  // 埋点
  const { run: postBuriedRun } = useRequest(
    (menu: string) => {
      return postBuried({
        portal: '服务平台',
        classification: isDeveloper
          ? '合作认证引导-成员'
          : '合作认证引导-管理员',
        menu,
      });
    },
    {
      manual: true,
    },
  );

  const { run: urgeRun } = useRequest(
    () => {
      return urgeAudit();
    },
    {
      manual: true,
      onSuccess: (res) => {
        switch (res) {
          case EUrgeResult.SUCCESS: {
            if (isNotPC) {
              Dialog.alert({
                title: '催办提醒已发送',
                content: '审核人员收到提醒后将尽快为您审核',
              });
            } else {
              Modal.success({
                title: '催办提醒已发送',
                content: '审核人员收到提醒后将尽快为您审核',
                okText: '我知道了',
              });
            }
            break;
          }
          case EUrgeResult.WAIT: {
            message.info('每3分钟仅可催办一次');
            break;
          }
          default:
            message.error('催办失败');
        }
      },
    },
  );

  const opButton: { [key in EEnterpriseStatus]?: () => void } = {
    [EEnterpriseStatus.NOT_JOIN]: () => (
      <Button
        type={'primary'}
        href={
          isDeveloper
            ? `${ID_URL}/#/enterprise`
            : `${ID_URL}/#/enterprise/cooperation`
        }
        target={'_blank'}
        onClick={() => {
          postBuriedRun(isDeveloper ? '去加入企业' : '立即认证');
        }}
      >
        {isDeveloper ? '去加入企业' : '立即认证'}
      </Button>
    ),
    [EEnterpriseStatus.NOT_CERT]: () => (
      <Button
        type={'primary'}
        href={`${ID_URL}/#/enterprise/cooperation`}
        target={'_blank'}
      >
        立即认证
      </Button>
    ),
    [EEnterpriseStatus.UNDER_REVIEW]: () => (
      <>
        {hasManagePermission(role) ? (
          <Space className={styles.urge_wrapper} direction={'vertical'}>
            <Button
              onClick={() => {
                postBuriedRun('我要催办');
                urgeRun();
              }}
            >
              我要催办
            </Button>
            <div className={styles.urge_text}>
              咨询/故障热线：400-811-8890转2
            </div>
          </Space>
        ) : (
          <Space className={styles.urge_a_wrapper}>
            <div className={styles.urge_text}>
              咨询/故障热线：400-811-8890转2
            </div>
            <Button
              type={'link'}
              style={{ padding: 0 }}
              onClick={() => {
                postBuriedRun('我要催办');
                urgeRun();
              }}
            >
              我要催办
            </Button>
          </Space>
        )}
      </>
    ),
    [EEnterpriseStatus.NOT_PASS]: () => (
      <Button
        type={'primary'}
        href={`${ID_URL}/#/enterprise/cooperation`}
        target={'_blank'}
        onClick={() => {
          postBuriedRun('再次申请');
        }}
      >
        再次申请
      </Button>
    ),
  };

  return (
    <>{enterpriseStatus !== undefined ? opButton[enterpriseStatus]!() : null}</>
  );
};

export default BootAuthButton;
