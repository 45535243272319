import {
  EEnterpriseAuth,
  EEnterpriseUserIdentify,
} from '@/types/enterpriseService';

export const manageEnterpriseAuth = [
  EEnterpriseUserIdentify.SUPER_ADMIN,
  EEnterpriseUserIdentify.ADMIN,
];

/**
 * 判断是否有企业管理权限
 * @param role
 */
export const hasManagePermission = (role: EEnterpriseUserIdentify) => {
  return manageEnterpriseAuth.includes(role);
};

/**
 * 判断是否有超管权限
 * @param role
 */
export const hasSuperManagePermission = (role: EEnterpriseUserIdentify) => {
  return role === EEnterpriseUserIdentify.SUPER_ADMIN;
};

/**
 * 判断是否是普通企业成员
 * @param role
 */
export const isOrdinaryMember = (role: EEnterpriseUserIdentify) => {
  return role === EEnterpriseUserIdentify.MEMBER;
};

/**
 * 判断是否可以使用技术支持服务/判断是否开通过服务
 * @param enterpriseAuth
 */
export const canUseTechSupport = (enterpriseAuth: EEnterpriseAuth) => {
  return (
    enterpriseAuth === EEnterpriseAuth.AVAILABLE ||
    enterpriseAuth === EEnterpriseAuth.LIMITED
  );
};
