import {
  requestDelete,
  requestGet,
  requestPost,
  requestPostJson,
  requestPut,
  requestPutJson,
} from '@/utils/request';

// 产品资讯-分页查询
export function getPushRecords(params: {
  labelId?: string;
  createdByName?: string;
  keyword?: string;
  current?: number;
  size?: number;
  createdTimeStart?: string;
  createdTimeEnd?: string;
}) {
  return requestGet(`/fcoin/api/myfr/information/page`, params);
}

export function getPushManageRecords(params: {
  labelId?: string;
  createdByName?: string;
  shelfStatus?: string;
  keyword?: string;
  current?: number;
  size?: number;
  createdTimeStart?: string;
  createdTimeEnd?: string;
}) {
  return requestGet(`/fcoin/api/myfr/information/manage/page`, params);
}
// 列举所有创建人
export function getAllPushCreators() {
  return requestGet(`/fcoin/api/myfr/information/creator/list`, {});
}

// 产品资讯-新增or修改
export function addOrEditPush(params: any) {
  return requestPostJson(`/fcoin/api/myfr/information`, params);
}

// 产品资讯-修改状态
export function updatePush(params: { id: string; shelfStatus: string }) {
  return requestPut(
    `/fcoin/api/myfr/information/status/change/${params.id}?shelfStatus=${params.shelfStatus}`,
    params,
  );
}

// 产品资讯-删除
// export function deletePush(params: { id: string }) {
//   return requestDelete(`/fcoin/api/myfr/information/${params.id}`, params);
// }

// 产品资讯-新增or修改-列举所有产品标签
export function getAllLabels() {
  return requestGet(`/fcoin/api/myfr/information/label/list`, {});
}

// 产品资讯-新增or修改-列举所授权的产品标签
export function getAuthLabels() {
  return requestGet(
    `/fcoin/api/fcoin/apply/authority/information/label/list`,
    {},
  );
}

// 产品资讯-新增or修改-列举所有内容类型
export function getAllContentTypes() {
  return requestGet(`/fcoin/api/myfr/information/content/type/list`, {});
}

// 标签-分页查询
export function getLabelRecords(params: {
  keyword?: string;
  current?: number;
  size?: number;
}) {
  return requestGet(`/fcoin/api/myfr/information/label/page`, params);
}

// 标签-新增or修改
export function addOrEditLabel(params: any) {
  return requestPostJson(`/fcoin/api/myfr/information/label`, params);
}

// 标签-删除
export function enableLabel(params: { id: string; enabled: boolean }) {
  return requestPut(
    `/fcoin/api/myfr/information/label/enable/${params.id}?enabled=${params.enabled}`,
    params,
  );
}

// 内容类型-分页查询
export function getContentTypeRecords(params: {
  keyword?: string;
  current?: number;
  size?: number;
}) {
  return requestGet(`/fcoin/api/myfr/information/content/type/page`, params);
}

// 内容类型新增or修改
export function addOrEditContentType(params: any) {
  return requestPostJson(`/fcoin/api/myfr/information/content/type`, params);
}

// 内容类型-删除
export function enableContentType(params: { id: string; enabled: boolean }) {
  return requestPut(
    `/fcoin/api/myfr/information/content/type/enable/${params.id}?enabled=${params.enabled}`,
    params,
  );
}

// 获取权限明细
export function getAllAuthorityRecords(params: any) {
  return requestGet('/fcoin/api/fcoin/apply/authority/page', params);
}

// 获取权限明细
export function sortLabels(params: any) {
  return requestPutJson('/fcoin/api/myfr/information/label/order', params);
}

// 添加授权
export function addAuthority(params: any) {
  return requestPostJson(
    '/fcoin/api/fcoin/apply/authority/add?type=MY_FANRUAN',
    params,
  );
}

//编辑授权
export function updateAuthority(id: any, params: any) {
  return requestPutJson(`/fcoin/api/fcoin/apply/authority/${id}`, params);
}

export function deleteAuthority(params: any) {
  return requestDelete(`/fcoin/api/fcoin/apply/authority/${params.id}`, params);
}
