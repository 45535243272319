import React, { useEffect, useState } from 'react';
import { Button, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload/index';
import styles from './index.less';
import { useRequest } from '@@/plugin-request/request';
import { submitFile } from '@/utils/requests/requests_utils';
import {
  calFileUploadParams,
  getFileNameAndType,
  randomStr,
} from '@/utils/index';
import axios from 'axios';
import { UploadFile } from 'antd/es/upload/interface';

type TUploadFile = {
  onChange?: (data: string) => void;
  value?: string;
};

const MAX_FILE_SIZE = 100;

const UploadFileCom: React.FC<TUploadFile> = (props) => {
  const { onChange, value } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const beforeUpload = (file: RcFile) => {
    const sizeLimit = file.size / 1024 / 1024 < 100;
    if (!sizeLimit) {
      message.error(`图片大小不能超过${MAX_FILE_SIZE}M`);
    }
    return sizeLimit;
  };

  const { loading, run: submitFileRequest } = useRequest(
    (fileName, contentType) => {
      return submitFile({ path: 'myfr', fileName, contentType });
    },
    {
      manual: true,
    },
  );
  useEffect(() => {
    if (value) {
      setFileList([
        {
          uid: randomStr(7),
          name: value, // 暂时用链接来当name，后面后端给了name的话要改
          url: value,
          status: 'done',
        },
      ]);
    }
  }, [value]);

  return (
    <div>
      <Upload
        fileList={fileList}
        className={styles.upload_icon_wrapper}
        beforeUpload={beforeUpload}
        onRemove={() => {
          setFileList([]);
          onChange?.('');
        }}
        customRequest={(info) => {
          let fileInfo = getFileNameAndType((info.file as RcFile).name);
          let uploadParams = calFileUploadParams(fileInfo.type, 'myfr');
          submitFileRequest(uploadParams.fileName, (info.file as RcFile).type)
            .then((res) => {
              console.log('res是');
              console.log(res);
              axios.request({
                method: 'PUT',
                url: res.signedUrl,
                withCredentials: false,
                headers:
                  Object.assign(res.actualSignedRequestHeaders, {
                    'Content-Disposition': `attachment; filename=${encodeURIComponent(
                      (info.file as RcFile).name,
                    )}`,
                  }) || {},
                data: info.file,
              });
            })
            .then(() => {
              onChange?.(
                `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
              );
              setFileList([
                {
                  uid: randomStr(7),
                  name: (info.file as RcFile)?.name,
                  url: `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
                  status: 'done',
                },
              ]);
              //setImageUrl(
              //  `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
              //);
            });
        }}
      >
        <Button loading={loading} icon={<UploadOutlined />}>
          上传文件
        </Button>
      </Upload>
    </div>
  );
};
export default UploadFileCom;
