import React, { useEffect, useState } from 'react';
import { TDragCardItem } from '@/types/common/index';
import styles from '@/components/dragCard/index.less';
import DragCardItem from '@/components/dragCard/dragCardItem';
import { Layout } from 'react-grid-layout';

type TMobileCardProps = {
  outData: TDragCardItem[];
  type?: 'PERSONAL' | 'INTERNAL';
  layout: Layout[];
};

const MobileCard: React.FC<TMobileCardProps> = ({
  type = 'PERSONAL',
  outData,
  layout,
}) => {
  const [idArray, setIdArray] = useState<string[]>([]);
  const [data] = useState<TDragCardItem[]>(outData);
  const createDragCard = () => {
    const dataSource = data.filter((item) => !item.delete);
    let dataSourceArray = [];
    for (let i = 0; i < idArray.length; i++) {
      const id = idArray[i];
      const item = dataSource.find((item) => item.id === id);
      if (item) {
        dataSourceArray.push(item);
      }
    }
    return dataSourceArray.map((item) => {
      return (
        <div key={item.id} className={styles.react_grid_item}>
          <DragCardItem item={item} type={type} isMobile />
        </div>
      );
    });
  };
  const orderCard = () => {
    const maxY = Math.max.apply(
      null,
      layout.map((item) => item.y),
    );
    let tempIdArrayResult = [];
    for (let y = 0; y <= maxY; y++) {
      const array = layout
        .filter((item) => item.y === y)
        .sort((a, b) => a.x - b.x);
      const tempIdArray = array.map((item) => item.i);
      tempIdArrayResult.push(...tempIdArray);
    }
    setIdArray(tempIdArrayResult);
  };
  useEffect(() => {
    orderCard();
  }, [layout]);

  return <div className={styles.drag_card_wrapper}>{createDragCard()}</div>;
};
export default MobileCard;
