import { createContext } from 'react';

export const DragCardContext = createContext<{
  cardNumber: number;
  onCardChange: (id?: string) => void;
  onDeleteCard: (id: string) => void;
}>({
  cardNumber: 0,
  onCardChange: () => {},
  onDeleteCard: () => {},
});
