// 引入企点 dom 组件 script 文件
export function importQidian(uid: string) {
  const varScript = document.createElement('script');
  varScript.innerHTML = `var wpaShowItemId=123; var qidian_ex1='${uid}';`;
  document.body.appendChild(varScript);
  const script = document.createElement('script');
  script.src =
    'https://wp.qiye.qq.com/qidian/3009113153/c16cd331ba516b595bd037be674c3505';
  script.id = 'qd3009113153c16cd331ba516b595bd037be674c3505';
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
}

// 唤起企点 dom 组件
export function activateQidianDom() {
  // @ts-ignore
  document.getElementById('root_qd').click();
}
