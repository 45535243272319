import React from 'react';
import ServiceCard, { IServiceCardItem } from '@/components/serviceCard';
import { useModel } from '@@/plugin-model/useModel';
import { Col, Row } from 'antd';
import { EEnterpriseStatus } from '@/types/enterpriseService';

type TNMProps = {};

const NavigateModule: React.FC<TNMProps> = () => {
  const { enterpriseStatus } = useModel('useEnterpriseModel', (model) => ({
    enterpriseStatus: model.enterpriseStatus,
  }));

  const specialConsultantStatuses = [
    EEnterpriseStatus.BASE_SERVICE_IN_EFFECT,
    EEnterpriseStatus.BASE_SERVICE_EXPIRED,
    EEnterpriseStatus.PREMIUM_SERVICE_IN_EFFECT,
    EEnterpriseStatus.PREMIUM_SERVICE_EXPIRED,
    EEnterpriseStatus.TEMPORARY_SERVICE_IN_EFFECT,
    EEnterpriseStatus.TEMPORARY_SERVICE_EXPIRED,
  ];

  const serviceCards: IServiceCardItem[] = [
    {
      title: '帮助文档中心',
      icon: `${process.env.ICON_PATH}/help_doc.png`,
      description: '最全最新的帆软文档平台',
      href: `${process.env.HELP_URL}`,
      isLock: false,
    },
    {
      title: '帆软市场',
      icon: `${process.env.ICON_PATH}/fr_market.png`,
      description: '帆软官方插件/组件/模版市场',
      href: `${process.env.MARKET}`,
      isLock: false,
    },
    // {
    //   title: '产品正式注册',
    //   icon:
    //     enterpriseStatus === undefined ||
    //     noCertStatus.includes(enterpriseStatus)
    //       ? `${process.env.ICON_PATH}/product_registration_no_auth.png`
    //       : `${process.env.ICON_PATH}/product_registration.png`,
    //   description: 'Finereport/FineBI产品licence获取',
    //   href: `${process.env.SERVICE_URL}/license`,
    //   isLock:
    //     enterpriseStatus === undefined ||
    //     noCertStatus.includes(enterpriseStatus),
    // },
    {
      title: '云端运维',
      icon: `${process.env.ICON_PATH}/operation_maintenance.png`,
      description: '发现运维问题，获得解决建议',
      href: `${process.env.SERVICE_URL}/OM`,
      isLock: false,
    },
    enterpriseStatus !== undefined &&
    specialConsultantStatuses.includes(enterpriseStatus)
      ? {
          title: '专属客户服务经理',
          icon:
            enterpriseStatus === EEnterpriseStatus.PREMIUM_SERVICE_IN_EFFECT
              ? `${process.env.ICON_PATH}/vvip_logo.png`
              : `${process.env.ICON_PATH}/vvip_logo_no_auth.png`,
          description:
            enterpriseStatus === EEnterpriseStatus.PREMIUM_SERVICE_IN_EFFECT
              ? '点击联系企业专属客户服务经理'
              : '该权益需开通企业高级服务',
          isLock: !(
            enterpriseStatus === EEnterpriseStatus.PREMIUM_SERVICE_IN_EFFECT
          ),
          isVVip: true,
        }
      : {
          title: '',
        },
  ];

  return (
    <div style={{ width: '100%' }}>
      <Row gutter={[24, 24]}>
        {serviceCards.map((item, index) => (
          <Col md={12} xs={12} sm={12} lg={6} xl={6} key={index}>
            <ServiceCard item={item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default NavigateModule;
