import React, { useEffect, useState } from 'react';
import styles from '@/pages/personalService/index.less';
import { SettingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import VisibleComponent from '@/components/visibleComponent/index';
import useGetUserInfo from '@/hooks/useGetUserInfo';
import { isSuperAdmin } from '@/utils/index';
import useGetDragInfo from '@/hooks/useGetDragInfo';
import { ComponentWrapper } from '@digit-fe/digit-component';

type TWelcomeProps = {
  type?: 'INTERNAL' | 'PERSONAL' | 'ENTERPRISE';
  welcomeContent: string;
  onSave?: (text: string) => void;
  welcomeExtra?: string;
};

const Welcome: React.FC<TWelcomeProps> = ({
  welcomeContent,
  type = 'PERSONAL',
  onSave = () => {},
  welcomeExtra = '',
}) => {
  const [visible, setVisible] = useState(false);
  const { uid, username, realname, nameEn } = useGetUserInfo();
  const [welcomeText, setWelcomeText] = useState(welcomeContent);
  const [form] = Form.useForm();
  const { isEdit } = useGetDragInfo();
  useEffect(() => {
    if (welcomeContent) {
      setWelcomeText(welcomeContent);
    }
  }, [welcomeContent]);
  const createInternalStr = () => {
    const strArray = [nameEn, realname].filter((item) => item);
    return `Hi~${strArray.join('-')}`;
  };

  return (
    <VisibleComponent visible={!!uid}>
      <div className={styles.welcome_wrapper}>
        <img
          className={styles.wufan}
          src={require('@/static/images/WuFan.png')}
        />
        <div
          style={{
            marginLeft: '14px',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100px',
          }}
        >
          <div className={styles.welcome_title}>
            {type === 'INTERNAL' ? createInternalStr() : `Hi~${username}`}
            <ComponentWrapper mode={'pc'}>
              <span className={styles.mgl5}>{welcomeExtra}</span>
            </ComponentWrapper>
          </div>
          <div
            className={styles.welcome_subtitle}
            dangerouslySetInnerHTML={{ __html: welcomeText }}
          />
        </div>

        <ComponentWrapper mode="pc">
          <VisibleComponent
            visible={!isEdit && isSuperAdmin(uid) && type !== 'ENTERPRISE'}
          >
            <div className={styles.button_wrapper}>
              <Button
                type={'link'}
                onClick={() => {
                  setVisible(true);
                }}
              >
                <SettingOutlined style={{ fontSize: '16px' }} />
                <span>欢迎语配置</span>
              </Button>
            </div>
          </VisibleComponent>
        </ComponentWrapper>
      </div>
      {welcomeText && (
        <Modal
          title={`${type === 'INTERNAL' ? '内部服务' : '社区导航'}-欢迎语配置`}
          open={visible}
          centered
          cancelText="取消"
          okText="确认"
          onOk={async () => {
            const data = await form.validateFields();
            setWelcomeText(data.text);
            onSave(data.text);
            setVisible(false);
          }}
          onCancel={() => {
            setVisible(false);
          }}
        >
          <Form form={form}>
            <Form.Item
              name="text"
              label="欢迎语"
              initialValue={welcomeText}
              help={
                <div>
                  <div>支持插入链接，格式示例：</div>
                  <div>
                    {`<a href="链接地址" target="_blank">
                    展示文字
                  </a>`}
                  </div>
                </div>
              }
              rules={[{ required: true, message: '请填写欢迎语' }]}
            >
              <Input placeholder="请配置欢迎语"></Input>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </VisibleComponent>
  );
};
export default Welcome;
