import React, { useEffect, useState } from 'react';
import { Modal, message, Form, Input } from 'antd';
import { Select, Upload, Switch, Tooltip } from 'antd';
import { submitFile } from '@/utils/requests/requests_utils';
import {
  calFileUploadParams,
  getCoverBase64,
  getFileNameAndType,
  randomStr,
} from '@/utils';
import { RcFile, UploadFile } from 'antd/es/upload';
import axios from 'axios';
import {
  addOrEditProduct,
  getAllUsers,
  getProductDetails,
  getProductDetailsList,
} from '@/utils/requests/requests_resource';
import { productManageItem } from '.';
import { isSuperAdmin } from '@/utils/index';
import { useModel, useRequest } from 'umi';

type Props = {
  addProductModalOpen: boolean;
  setAddProductModalOpen: Function;
  num?: number;
  // details?: productManageItem;
  id?: string;
  refresh: number;
  setRefresh: Function;
  modalTitle?: string;
  okText?: string;
  submitMessage?: string;
};

const AddOrEditProductModal: React.FC<Props> = ({
  addProductModalOpen,
  setAddProductModalOpen,
  num,
  id,
  setRefresh,
  refresh,
  modalTitle = '新增产品',
  okText = '确认',
  submitMessage = '添加产品成功',
}) => {
  const [form] = Form.useForm();
  const [title, setTitle] = useState<string | undefined>();
  const [brief, setBrief] = useState<string | undefined>();
  const [index, setIndex] = useState(num);
  const [logo, setLogo] = useState<string | undefined>();
  const [enabled, setEnabled] = useState(true);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [coverImage, setCoverImage] = useState<any>();
  const [managerList, setManagerList] = useState<
    { nameEn: string; nameZh: string }[]
  >([]);
  const [managerValue, setManagerValue] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string | undefined>();
  const [managerOptions, setManagerOptions] = useState<any>();
  const { initialState } = useModel('@@initialState');

  const getProductDetailsRequest = useRequest(
    (id: string) => {
      return getProductDetails({ id });
    },
    {
      manual: true,
      onSuccess: (res) => {
        form.setFieldsValue({
          title: res?.title,
          brief: res?.brief,
          managerValue: res.managerList.map(
            (item: { nameEn: string; nameZh: string }) =>
              `${item.nameEn}-${item.nameZh}`,
          ),
          logo: res?.logo,
          enabled: res?.enabled,
        });
        setIndex(num ? num : res?.index);
        setTitle(res?.title);
        setBrief(res?.brief);
        setLogo(res?.logo);
        setCoverImage(res?.logo);
        setEnabled(res?.enabled);
        setManagerList(res.managerList);
        setFileList(
          res?.logo
            ? [
                {
                  uid: randomStr(7),
                  name: res?.logo,
                  url: res?.logo,
                  status: 'done',
                },
              ]
            : [],
        );

        setManagerValue(
          res.managerList.map(
            (item: { nameEn: string; nameZh: string }) =>
              `${item.nameEn}-${item.nameZh}`,
          ),
        );
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    id && getProductDetailsRequest.run(id);
  }, [id, num]);

  const submitFileRequest = useRequest(
    (fileName: string, contentType: string) => {
      return submitFile({ path: 'myfr', fileName, contentType });
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  const addOrEditProductRequest = useRequest(
    () => {
      return addOrEditProduct(
        JSON.stringify({
          id,
          title,
          brief,
          logo,
          index,
          enabled,
          managerList,
        }),
      );
    },
    {
      manual: true,
      onSuccess: (res) => {},
      onError: (e) => {},
    },
  );

  const getAllUsersRequest = useRequest(
    () => {
      return getAllUsers({ keyword });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setManagerOptions(
          res.map((item: any) =>
            Object.assign({}, item, {
              value: `${item.nameEn}-${item.nameZh}`,
              label: `${item.nameEn}-${item.nameZh}`,
            }),
          ),
        );
      },
      onError: (e) => {},
    },
  );

  useEffect(() => {
    getAllUsersRequest.run();
  }, []);

  return (
    <Modal
      open={addProductModalOpen}
      title={modalTitle}
      width={664}
      destroyOnClose={true}
      okText={okText}
      onOk={() => {
        form.submit();
      }}
      cancelText="取消"
      onCancel={() => {
        setAddProductModalOpen(false);
      }}
    >
      <Form
        name="recommendedContent"
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        initialValues={{}}
        onFinish={() => {
          addOrEditProductRequest.run().then(() => {
            setManagerList([]);
            setAddProductModalOpen(false);
            message.success(submitMessage);
            setRefresh(refresh + 1);
          });
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <Form.Item
          label="产品标题"
          name="title"
          rules={[{ required: true, message: '请选择产品标签' }]}
        >
          <Input
            placeholder="请输入..."
            showCount
            maxLength={20}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Form.Item>
        {isSuperAdmin(initialState?.uid) && (
          <Form.Item
            label="管理员"
            name="managerValue"
            rules={[{ required: true, message: '请选择管理员' }]}
          >
            <Select
              placeholder="请选择..."
              options={managerOptions}
              showSearch
              mode="multiple"
              allowClear
              onChange={(value: string[]) => {
                console.log(value);

                let managerList: { nameEn: string; nameZh: string }[] = [];
                value.forEach((item) => {
                  let manager = managerOptions.find(
                    (elem: any) => elem.value == item,
                  );
                  managerList.push({
                    nameEn: manager?.nameEn,
                    nameZh: manager?.nameZh,
                  });
                });
                setManagerList(managerList);
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          label="产品简介"
          name="brief"
          rules={[{ required: true, message: '请输入标题' }]}
        >
          <Input
            placeholder="请输入..."
            onChange={(event) => setBrief(event.target.value)}
          />
        </Form.Item>
        {isSuperAdmin(initialState?.uid) && (
          <Form.Item label="是否启用" valuePropName="checked">
            <Switch
              checked={enabled}
              onChange={(checked: boolean) => setEnabled(checked)}
            />
          </Form.Item>
        )}
        <Form.Item label="产品logo" valuePropName="fileList">
          <div style={{ color: '#C5C7CE', lineHeight: '27px' }}>
            支持上传文件大小不超过 500 KB 的.png格式文件
          </div>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            accept={'.png'}
            fileList={fileList}
            showUploadList={false}
            customRequest={(info) => {
              let fileInfo = getFileNameAndType((info.file as RcFile).name);
              let uploadParams = calFileUploadParams(fileInfo.type, 'myfr');
              submitFileRequest
                .run(uploadParams.fileName, (info.file as RcFile).type)
                .then((res) => {
                  axios.request({
                    method: 'PUT',
                    url: res.signedUrl,
                    withCredentials: false,
                    headers:
                      Object.assign(res.actualSignedRequestHeaders, {
                        'Content-Disposition': `attachment; filename=${encodeURIComponent(
                          (info.file as RcFile).name,
                        )}`,
                      }) || {},
                    data: info.file,
                  });
                })
                .then(() => {
                  setLogo(
                    `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
                  );
                  getCoverBase64(info.file, (imageUrl: any) => {
                    setCoverImage(imageUrl);
                  });
                  setFileList([
                    {
                      uid: randomStr(7),
                      name: (info.file as RcFile)?.name,
                      url: `https://shequ-oss.obs.cn-east-2.myhuaweicloud.com:443/myfr/${uploadParams.fileName}`,
                      status: 'done',
                    },
                  ]);
                });
            }}
            beforeUpload={(file: RcFile) => {
              const isJpgOrPng = file.type === 'image/png';
              if (!isJpgOrPng) {
                message.error('只支持.png格式!');
              }
              const isLt2M = file.size / 1024 / 1024 < 0.5;
              if (!isLt2M) {
                message.error('大小不超过500KB!');
              }
              return isJpgOrPng && isLt2M;
            }}
            onRemove={() => {
              setFileList([]);
            }}
          >
            {coverImage ? (
              <Tooltip title="重新上传">
                <img src={coverImage} alt="avatar" style={{ width: '100%' }} />
              </Tooltip>
            ) : (
              <div>
                <div style={{ marginTop: 8 }}>PIC</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOrEditProductModal;
