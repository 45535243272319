import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Person } from '@/pages/enterpriseService/components/contactFR/index';
import { ComponentWrapper } from '@digit-fe/digit-component';
import { Modal, Space } from 'antd';
import styles from './index.less';
import { Popup } from 'antd-mobile';

export interface IContactPersonItem {
  icon: string;
  tag: string;
  person?: Person[];
}

type TCMProps = {
  list: IContactPersonItem[];
  isVVip?: boolean;
};

const ContactModal: React.ForwardRefRenderFunction<{}, TCMProps> = (
  { list, isVVip = false },
  ref,
) => {
  const [visible, setVisible] = useState<boolean>(false);

  const openModal = () => {
    setVisible(true);
  };

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  return (
    <>
      <ComponentWrapper mode={'pc'}>
        <Modal
          title={'可拨打下列电话咨询购买'}
          open={visible}
          onCancel={() => {
            setVisible(false);
          }}
          footer={null}
          style={{ paddingBottom: 20 }}
        >
          {list.length > 1 ? (
            <Space size={16} direction={'vertical'}>
              {list.map((item, index) => (
                <HorizontalItem personItem={item} key={index} />
              ))}
            </Space>
          ) : (
            <VerticalItem personItem={list[0]} />
          )}
        </Modal>
      </ComponentWrapper>
      <ComponentWrapper mode={'notPC'}>
        <Popup
          visible={visible}
          showCloseButton
          onMaskClick={() => {
            setVisible(false);
          }}
          onClose={() => {
            setVisible(false);
          }}
          bodyStyle={{
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            height: '268px',
          }}
        >
          <div className={styles.contact_fr_mobile_popup_title}>
            可拨打下列电话咨询购买
          </div>
          <div className={styles.contact_popup_body}>
            {list.length > 1 ? (
              list.map((item, index) => (
                <VerticalItem personItem={item} key={index} />
              ))
            ) : (
              <VerticalItem personItem={list[0]} />
            )}
          </div>
        </Popup>
      </ComponentWrapper>
    </>
  );
};

export default forwardRef(ContactModal);

const HorizontalItem: React.FC<{ personItem: IContactPersonItem }> = ({
  personItem,
}) => (
  <Space className={styles.horizontal_layout_info} size={16}>
    <img src={personItem.icon} className={styles.horizontal_layout_info} />
    <div>
      <div className={styles.modal_tag}>{personItem.tag}</div>
      <Space direction={'vertical'} size={4}>
        {personItem.person
          ? personItem.person.map((item, index) => (
              <Space key={index} size={16}>
                {item.name ? (
                  <div className={styles.modal_person_name}>{item.name}</div>
                ) : null}
                <div className={styles.modal_person_phone}>{item.phone}</div>
              </Space>
            ))
          : null}
      </Space>
    </div>
  </Space>
);

const VerticalItem: React.FC<{ personItem: IContactPersonItem }> = ({
  personItem,
}) => (
  <div className={styles.vertical_layout_info}>
    <div className={styles.item_icon}>
      <img src={personItem.icon} />
    </div>
    <div className={styles.modal_tag}>{personItem.tag}</div>
    <Space direction={'vertical'} size={4}>
      {personItem.person
        ? personItem.person.map((item, index) => (
            <Space size={8} key={index}>
              {item.name ? (
                <div className={styles.modal_person_name}>{item.name}</div>
              ) : null}
              <div className={styles.modal_person_phone}>{item.phone}</div>
            </Space>
          ))
        : null}
    </Space>
  </div>
);
