import {
  requestDelete,
  requestGet,
  requestPost,
  requestPostJson,
  requestPut,
  requestPutJson,
} from '@/utils/request';

// 列举所有产品资料
export function getAllProduct() {
  return requestGet(`/fcoin/api/myfr/product/information`, {});
}

// 列举所有待管理的产品资料
export function getProductManageRecords() {
  return requestGet(`/fcoin/api/myfr/product/information/manage`, {});
}

// 重新排序产品资料
export function sortProductManageRecords(params: any) {
  return requestPutJson(`/fcoin/api/myfr/product/information/order`, params);
}

// 创建或修改产品资料
export function addOrEditProduct(params: any) {
  return requestPostJson(`/fcoin/api/myfr/product/information`, params);
}

// 创建或修改产品资料
export function deleteProduct(params: any) {
  return requestDelete(
    `/fcoin/api/myfr/product/information/${params.id}`,
    params,
  );
}

// 获取产品资料详情
export function getProductDetails(params: { id: string }) {
  return requestGet(`/fcoin/api/myfr/product/information/${params.id}`, params);
}
// 获取产品资料详情
export function getProductDetailsList(params: { id: string }) {
  return requestGet(
    `/fcoin/api/myfr/product/information/${params.id}/item/list`,
    params,
  );
}
// 重新排序产品资料内容
export function sortProductDetailsList(id: string, params: any) {
  return requestPutJson(
    `/fcoin/api/myfr/product/information/${id}/item/order`,
    params,
  );
}

// 删除产品资料内容
export function deleteProductList(params: any) {
  return requestDelete(
    `/fcoin/api/myfr/product/information/item/${params.id}`,
    params,
  );
}

// 创建或修改产品资料内容
export function addOrEditProductContent(id: string, params: any) {
  return requestPostJson(
    `/fcoin/api/myfr/product/information/${id}/item`,
    params,
  );
}

// 列举用户
export function getAllUsers(params: { keyword?: string }) {
  return requestGet(`/fcoin/api/myfr/product/information/user/list`, params);
}
