import { IRouteComponentProps } from 'umi';
import styles from './index.less';
import { Tabs } from 'antd';
import { history } from '@@/core/history';
import React, { useEffect, useState } from 'react';
import { calFrontTabsUnderLine } from '@/utils/tabs';
import useGetUserInfo from '@/hooks/useGetUserInfo';
import { ComponentWrapper } from '@digit-fe/digit-component';
import { SendOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useRequest } from '@@/plugin-request/request';
import { getCurrentUserInfo } from '@/utils/requests/requests_utils';

export default function FrontBaseLayout(props: IRouteComponentProps) {
  const { children, location } = props;
  const { nameEn } = useGetUserInfo();
  const [currentUserInfo, setCurrentUserInfo] = useState<any>();
  const [tabs, setTabs] = useState([
    {
      key: 'personalService',
      label: `最新资讯`,
    },
    {
      key: 'bbsModule',
      label: `社区导航`,
    },
    {
      key: 'productResource',
      label: `产品资料`,
    },
  ]);
  const [tabValue, setTabValue] = useState<
    | 'personalService'
    | 'bbsModule'
    | 'productResource'
    | 'internalService'
    | 'enterpriseService'
    | undefined
  >();

  const getCurrentUserInfoRequest = useRequest(
    () => {
      return getCurrentUserInfo({ type: 'MY_FANRUAN' });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setCurrentUserInfo(res);
      },
      onError: (e) => {},
    },
  );

  const handleTabChange = (activeKey: any) => {
    setTabValue(activeKey);
    activeKey == 'personalService' && history.push('/');
    activeKey == 'productResource' && history.push('/resource');
    activeKey == 'internalService' && history.push('/internal');
    activeKey == 'bbsModule' && history.push('/module');
  };

  useEffect(() => {
    location.pathname == '/' && setTabValue('personalService');
    location.pathname == '/module' && setTabValue('bbsModule');
    location.pathname == '/resource' && setTabValue('productResource');
    location.pathname == '/internal' && setTabValue('internalService');
    location.pathname == '/service' && setTabValue('enterpriseService');
    calFrontTabsUnderLine(
      !(location.pathname == '/internal' && !nameEn) &&
        !(location.pathname == '/service'),
    );
  }, [location.pathname]);
  useEffect(() => {
    // 内部服务仅有英文名时可见
    if (nameEn) {
      setTabs((prevState) => {
        prevState.push({
          key: 'internalService',
          label: `内部服务`,
        });
        return [...prevState];
      });
      getCurrentUserInfoRequest.run();
    }
  }, [nameEn]);

  return (
    <div className={styles.content}>
      <div className={styles.page_wrapper}>
        {window.location.pathname !== '/service' && (
          <ComponentWrapper>
            <div className={styles.frontTabBar}>
              <Tabs
                activeKey={tabValue}
                tabBarGutter={40}
                items={tabs}
                onChange={handleTabChange}
              />
              {(currentUserInfo?.admin || currentUserInfo?.authorized) && (
                <div
                  className={styles.layout_vertical_center}
                  style={{ cursor: 'pointer', marginBottom: 20 }}
                  onClick={() => {
                    history.push('/news/add');
                  }}
                >
                  <SendOutlined style={{ fontSize: 16, color: '#0082fc' }} />
                  <span
                    style={{ color: '#0028fc' }}
                    className={classNames(styles.f14, styles.lh24, styles.mgl4)}
                  >
                    发布
                  </span>
                </div>
              )}
            </div>
          </ComponentWrapper>
        )}
        {children}
      </div>
    </div>
  );
}
