import React from 'react';
import cx from 'classnames';
import styles from './index.less';
import {
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Tooltip,
} from 'antd';
import { phoneCode } from '@/utils/phoneCode';
import { useRequest } from 'umi';
import {
  addMember,
  getEnterprises,
  getUserInfo,
} from '@/utils/requests/requests_addMembers';
import { useEffect, useState } from 'react';
import debouncePromise from 'debounce-promise';
import { Rule } from 'antd/lib/form';
import { ComponentWrapper } from '@digit-fe/digit-component';
import { useMediaQuery } from 'react-responsive';
import { List, Popup } from 'antd-mobile';
import AddMembersAuthorityModal from './authority';
import useGetUserInfo from '@/hooks/useGetUserInfo';

type AMFProps = {
  isAdmin: boolean;
};

type FieldType = {
  mvid?: number | null;
  remark?: null | string;
  role?: number | null;
  mobile?: string | null;
  location?: string | null;
  uid?: string | null;
};

export interface EnterpriseInfo {
  adminRealName?: string | null;
  adminUid?: string | null;
  adminUsername?: string | null;
  companyName?: string | null;
  mvid?: number | null;
  value?: number | null;
  label?: string | null;
}

export interface UserInfo {
  companyName?: string;
  companyRole?: number;
  mobile?: string;
  mvid?: string;
  uid?: number;
  username?: string;
  realName?: string;
}

const AddMembersForm: React.FC<AMFProps> = ({ isAdmin }) => {
  const isNotPc = useMediaQuery({ query: '(max-width: 1199px)' });

  const [enterprisesOptions, setEnterprisesOptions] = useState<
    EnterpriseInfo[]
  >([]);
  const [admin, setAdmin] = useState(false);

  const [userInfo, setUserInfo] = useState<UserInfo | undefined>();
  const [mobileTip, setMobileTip] = useState(false);
  const [mvid, setMvid] = useState<string | undefined>();
  const [enabledSubmit, setEnabledSubmit] = useState(false);
  const [mobile, setMobile] = useState<string | undefined>();
  const [submitPopupVisible, setSubmitPopupVisible] = useState(false);
  const [finishVisible, setFinishVisible] = useState(false);
  const [keyword, setKeyword] = useState<string | undefined>();
  const [permissionManegeModalOpen, setPermissionManegeModalOpen] =
    useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectValue, setSelectValue] = useState<string | undefined>('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [keywordMobile, setKeywordMobile] = useState<string>('');

  const [form] = Form.useForm();

  const { run: getEnterprisesRun } = useRequest(
    (key: string) => {
      return getEnterprises({ key });
    },
    {
      manual: true,
      onSuccess: (res) => {
        setEnterprisesOptions(
          res.map((item: EnterpriseInfo) => ({
            ...item,
            value: item.mvid,
            label: item.companyName,
          })),
        );
        setLoading(false);
      },
    },
  );
  const { run: getUserInfoRun } = useRequest(
    (key: string) => {
      return getUserInfo({ key, type: 'MOBILE', limit: 1 });
    },
    {
      manual: true,
      onSuccess: (res) => {},
    },
  );
  const { run: addMemberRun } = useRequest(
    (value) => {
      return addMember(JSON.stringify(value));
    },
    {
      manual: true,
      onSuccess: (res) => {},
    },
  );

  const debouncedSearch = debouncePromise((value: string) => {
    getEnterprisesRun(value);
  }, 1000);

  const getEnterprisesOptions = (value: string) => {
    debouncedSearch(value);
  };

  const submitTip = () => {
    if (mobile && mvid) {
      if (userInfo) {
        if (userInfo.companyRole == 1 && userInfo.mvid !== mvid) {
          return (
            <div
              style={{ color: '#FF7A45', marginBottom: 10, display: 'flex' }}
            >
              <ExclamationCircleOutlined
                style={{ marginRight: 8, marginTop: 4 }}
              />
              客户是其他企业超级管理员，请让客户解散或移交企业后再继续操作
            </div>
          );
        } else if (
          (userInfo.companyRole == 0 || userInfo.companyRole == 2) &&
          userInfo.mvid !== mvid
        ) {
          return (
            <div
              style={{ color: '#FF7A45', marginBottom: 10, display: 'flex' }}
            >
              <ExclamationCircleOutlined
                style={{ marginRight: 8, marginTop: 4 }}
              />
              客户已加入其他企业，继续操作将退出并加入新的企业
            </div>
          );
        } else if (userInfo.companyRole == 1 && userInfo.mvid == mvid) {
          return (
            <div
              style={{ color: '#FF7A45', marginBottom: 10, display: 'flex' }}
            >
              <ExclamationCircleOutlined
                style={{ marginRight: 8, marginTop: 4 }}
              />
              客户已是所选企业超级管理员，无需再次加入
            </div>
          );
        } else if (
          (userInfo.companyRole == 0 || userInfo.companyRole == 2) &&
          userInfo.mvid == mvid
        ) {
          return (
            <div
              style={{ color: '#FF7A45', marginBottom: 10, display: 'flex' }}
            >
              <ExclamationCircleOutlined
                style={{ marginRight: 8, marginTop: 4 }}
              />
              客户已加入所选企业，继续操作将仅修改客户角色
            </div>
          );
        } else {
          return <></>;
        }
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (!keyword) {
      setEnterprisesOptions([]);
    }
  }, [keyword]);

  useEffect(() => {
    if (mobile && mvid) {
      if (userInfo) {
        if (userInfo.companyRole == 1 && userInfo.mvid !== mvid) {
          setEnabledSubmit(false);
        } else if (
          (userInfo.companyRole == 0 || userInfo.companyRole == 2) &&
          userInfo.mvid !== mvid
        ) {
          setEnabledSubmit(true);
        } else if (userInfo.companyRole == 1 && userInfo.mvid == mvid) {
          setEnabledSubmit(false);
        } else if (
          (userInfo.companyRole == 0 || userInfo.companyRole == 2) &&
          userInfo.mvid == mvid
        ) {
          setEnabledSubmit(true);
        } else {
          setEnabledSubmit(true);
        }
      } else {
        setEnabledSubmit(true);
      }
    } else {
      setEnabledSubmit(false);
    }
  }, [mvid, mobile, userInfo]);

  return (
    <>
      <ComponentWrapper>
        {isAdmin && (
          <Button
            type="link"
            style={{ position: 'absolute', right: 20 }}
            onClick={() => setPermissionManegeModalOpen(true)}
          >
            权限管理
          </Button>
        )}
      </ComponentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <div
          className={styles.tip}
          style={{ width: '100%', minWidth: 343, maxWidth: 400 }}
        >
          <InfoCircleOutlined
            style={{ fontSize: 14, color: '#1890FF', marginRight: 8 }}
          />
          请先和客户核实身份后再进行操作，将会记录操作日志
        </div>
        <Form
          name="addMembers"
          form={form}
          style={{ width: '100%', minWidth: 343, maxWidth: 400 }}
          initialValues={{ role: 0, location: '+86' }}
          onFinish={() => {
            isNotPc
              ? setSubmitPopupVisible(true)
              : Modal.confirm({
                  title: '确认向企业添加成员吗？',
                  icon: <QuestionCircleOutlined />,
                  content: (
                    <>
                      <div>
                        目标企业：
                        {
                          enterprisesOptions.find(
                            (item) => item.mvid === form.getFieldsValue().mvid,
                          )?.companyName
                        }
                        （mvid：{form.getFieldsValue().mvid}）
                      </div>
                      <div style={{ marginTop: 8 }}>
                        目标成员手机号：{form.getFieldsValue().location}{' '}
                        {form.getFieldsValue().mobile}
                      </div>
                      <div style={{ marginTop: 8 }}>
                        备注名：{form.getFieldsValue().remark}
                      </div>
                      <div style={{ marginTop: 8 }}>
                        成员角色：
                        {form.getFieldsValue().role === 0
                          ? '普通成员'
                          : '管理员'}
                      </div>
                    </>
                  ),
                  cancelText: '取消',
                  okText: '确认',
                  onOk: () => {
                    if (userInfo) {
                      addMemberRun({
                        mvid: form.getFieldsValue().mvid,
                        remark: form.getFieldsValue().remark,
                        role: form.getFieldsValue().role,
                        uid: userInfo?.uid,
                      }).then(() => {
                        Modal.success({
                          icon: <CheckCircleOutlined />,
                          title: '添加成功',
                          content: '请告知客户刷新页面或重新登录后查看。',
                          okText: '我知道了',
                        });
                      });
                    } else {
                      addMemberRun({
                        location: form.getFieldsValue().location,
                        mobile: form.getFieldsValue().mobile,
                        mvid: form.getFieldsValue().mvid,
                        remark: form.getFieldsValue().remark,
                        role: form.getFieldsValue().role,
                      }).then(() => {
                        Modal.success({
                          icon: <CheckCircleOutlined />,
                          title: '添加成功',
                          content: `已用 ${form.getFieldsValue().location} ${
                            form.getFieldsValue().mobile
                          } 为客户创建账号并加入企业。请告知客户使用手机验证码登录后查看。`,
                          okText: '我知道了',
                        });
                      });
                    }
                  },
                });
          }}
          onFinishFailed={(errorInfo) => {
            console.log('Failed:', errorInfo);
          }}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label="要加入的企业"
            name="mvid"
            rules={[{ required: true, message: '请选择要加入的企业' }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 20 }}
          >
            {isNotPc ? (
              <>
                <Input.Search
                  placeholder="输入企业名称/mvid/超级管理员姓名查询企业"
                  value={keywordMobile}
                  onSearch={(value) => {
                    setKeyword(value);
                    setVisible(true);
                    value && getEnterprisesOptions(value);
                    setLoading(true);
                  }}
                  onChange={(e) => {
                    setKeywordMobile(e.target.value);
                  }}
                />
                <Popup
                  visible={visible}
                  onMaskClick={() => {
                    setVisible(false);
                  }}
                  bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    minHeight: '40vh',
                    maxHeight: '80vh',
                    textAlign: loading ? 'center' : 'left',
                    overflow: 'auto',
                  }}
                >
                  {loading ? (
                    <Spin style={{ marginTop: 20 }} />
                  ) : (
                    <List>
                      {enterprisesOptions?.map((item) => {
                        const strTitle = item.label || '';
                        const index = strTitle
                          .toLowerCase()
                          .indexOf(keyword?.toLowerCase() || '');
                        const beforeStr = strTitle.substring(0, index);
                        const afterStr = strTitle.slice(
                          index + (keyword || '').length,
                        );
                        const title =
                          index > -1 ? (
                            <span>
                              {beforeStr}
                              <span style={{ color: '#f50' }}>
                                {strTitle.slice(
                                  index,
                                  index + (keyword || '').length,
                                )}
                              </span>
                              {afterStr}
                            </span>
                          ) : (
                            <span>{strTitle}</span>
                          );

                        const strName = item.adminRealName || '';
                        const indexName = strName
                          .toLowerCase()
                          .indexOf(keyword?.toLowerCase() || '');
                        const beforeStrName = strName.substring(0, index);
                        const afterStrName = strName.slice(
                          index + (keyword || '').length,
                        );
                        const name =
                          indexName > -1 ? (
                            <span>
                              {beforeStrName}
                              <span style={{ color: '#f50' }}>
                                {strName.slice(
                                  indexName,
                                  indexName + (keyword || '').length,
                                )}
                              </span>
                              {afterStrName}
                            </span>
                          ) : (
                            <span>{strName}</span>
                          );

                        return (
                          <List.Item
                            key={item.value}
                            onClick={() => {
                              console.log(item.value);
                              setKeywordMobile(item?.label || '');
                              setMvid(item.value);
                              setVisible(false);
                              form.setFieldValue('mvid', item.value);
                            }}
                          >
                            <div>{title}</div>
                            <Row>
                              <Col span={12}>mvid：{item.mvid}</Col>
                              <Col span={12}>
                                超级管理员：
                                {keyword && item.adminRealName ? name : '--'}
                              </Col>
                            </Row>
                          </List.Item>
                        );
                      })}
                    </List>
                  )}
                </Popup>
              </>
            ) : (
              <Select
                defaultActiveFirstOption
                allowClear
                showSearch
                filterOption={false}
                placeholder="输入企业名称/mvid/超级管理员姓名查询企业"
                style={{ width: '100%', minWidth: 343, maxWidth: 400 }}
                optionLabelProp="title"
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>未找到企业，换个关键词试试</span>}
                  />
                }
                onSearch={(value) => {
                  setKeyword(value);
                  value && getEnterprisesOptions(value);
                }}
                onChange={(value) => {
                  setMvid(value);
                  form.setFieldValue('mvid', value);
                }}
                onClear={() => {
                  setEnterprisesOptions([]);
                }}
              >
                {enterprisesOptions?.map((item) => {
                  const strTitle = item.label || '';
                  const index = strTitle
                    .toLowerCase()
                    .indexOf(keyword?.toLowerCase() || '');
                  const beforeStr = strTitle.substring(0, index);
                  const afterStr = strTitle.slice(
                    index + (keyword || '').length,
                  );
                  const title =
                    index > -1 ? (
                      <span>
                        {beforeStr}
                        <span style={{ color: '#f50' }}>
                          {strTitle.slice(
                            index,
                            index + (keyword || '').length,
                          )}
                        </span>
                        {afterStr}
                      </span>
                    ) : (
                      <span>{strTitle}</span>
                    );

                  const strName = item.adminRealName || '';
                  const indexName = strName
                    .toLowerCase()
                    .indexOf(keyword?.toLowerCase() || '');
                  const beforeStrName = strName.substring(0, index);
                  const afterStrName = strName.slice(
                    index + (keyword || '').length,
                  );
                  const name =
                    indexName > -1 ? (
                      <span>
                        {beforeStrName}
                        <span style={{ color: '#f50' }}>
                          {strName.slice(
                            indexName,
                            indexName + (keyword || '').length,
                          )}
                        </span>
                        {afterStrName}
                      </span>
                    ) : (
                      <span>{strName}</span>
                    );

                  return (
                    <Select.Option
                      title={item.label}
                      value={item.value}
                      key={item.value}
                    >
                      <div>{title}</div>
                      <Row>
                        <Col span={12}>mvid：{item.mvid}</Col>
                        <Col span={12}>
                          超级管理员：
                          {keyword && item.adminRealName ? name : '--'}
                        </Col>
                      </Row>
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item<FieldType>
            label="客户手机号"
            name="mobile"
            rules={[
              {
                required: true,
                validator: (_: Rule, value: string) => {
                  if (form.getFieldsValue().location == '+86') {
                    if (
                      /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/.test(
                        value,
                      )
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error('请输入正确的客户手机号'),
                      );
                    }
                  } else {
                    if (/^\d{7,11}$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error('请输入正确的客户手机号'),
                      );
                    }
                  }
                },
              },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: mobileTip ? 8 : 0 }}
          >
            <Input
              addonBefore={
                <Form.Item<FieldType> name="location">
                  <Select style={{ width: 80 }}>
                    {phoneCode.map((item) => (
                      <Select.Option value={item.value}>
                        {item.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              }
              placeholder="请输入..."
              onBlur={(event) => {
                event.target.value &&
                  getUserInfoRun(event.target.value).then((res) => {
                    setMobile(event.target.value);
                    form.setFieldValue('mobile', event.target.value);
                    setUserInfo(res[0]);
                    if (res.length > 0) {
                      setUserInfo(res[0]);
                    } else {
                      setUserInfo(undefined);
                    }
                    setMobileTip(true);
                  });
              }}
            />
          </Form.Item>
          {mobileTip && (
            <div>
              {userInfo ? (
                <div className={styles.user_info}>
                  <div>
                    <span
                      className={cx(
                        styles.color_17243E,
                        styles.fw600,
                        styles.mgr20,
                      )}
                    >
                      {userInfo?.username}
                    </span>
                    <span className={styles.color_808696}>
                      uid：{userInfo?.uid}
                    </span>
                  </div>
                  <div className={cx(styles.mgt8, styles.color_808696)}>
                    <span className={styles.mgr14}>手机号：</span>
                    <span className={styles.color_515A6E}>
                      {userInfo?.mobile}
                    </span>
                    <span className={cx(styles.mgr14, styles.mgl30)}>
                      真实姓名：
                    </span>
                    <span className={styles.color_515A6E}>
                      {userInfo?.realName ? userInfo.realName : '--'}
                    </span>
                  </div>
                </div>
              ) : (
                <div style={{ color: '#FF4D4F', lineHeight: '27px' }}>
                  未匹配到已有账号，确认提交后将自动为客户创建帆软账号，客户可用手机号+验证码登录
                </div>
              )}
            </div>
          )}
          <Form.Item<FieldType>
            label="备注名"
            name="remark"
            extra="备注名用于企业管理员区分成员身份，不对成员展示"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 20, marginTop: 24 }}
          >
            <Input placeholder="请输入备注名..." showCount maxLength={20} />
          </Form.Item>
          <Form.Item<FieldType>
            label="加入企业后的角色"
            name="role"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 40 }}
          >
            <Radio.Group>
              <Radio value={0}>普通成员</Radio>
              <Radio value={2}>管理员</Radio>
            </Radio.Group>
          </Form.Item>
          <div> {submitTip()}</div>
          <Tooltip
            title={
              !(form.getFieldValue('mvid') && form.getFieldValue('mobile'))
                ? '请填写要加入的企业和客户手机号'
                : ''
            }
          >
            <Button
              type="primary"
              style={{
                width: '100%',
                minWidth: 343,
                maxWidth: 400,
                height: 40,
              }}
              disabled={!enabledSubmit}
              onClick={() => form.submit()}
            >
              确认添加
            </Button>
          </Tooltip>
        </Form>
      </div>
      <Popup
        visible={submitPopupVisible}
        onMaskClick={() => {
          setSubmitPopupVisible(false);
        }}
        showCloseButton
        onClose={() => {
          setSubmitPopupVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '340px',
          padding: '24px 16px',
        }}
      >
        <div
          style={{
            fontSize: 16,
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 24,
            alignItems: 'center',
          }}
        >
          <QuestionCircleOutlined
            style={{ color: '#FFA940', marginRight: 16 }}
          />
          确认向企业添加成员吗？
        </div>
        <div>
          目标企业：
          {
            enterprisesOptions.find(
              (item) => item.mvid === form.getFieldsValue().mvid,
            )?.companyName
          }
          （mvid：{form.getFieldsValue().mvid}）
        </div>
        <div style={{ marginTop: 8 }}>
          目标成员手机号：{form.getFieldsValue().location}{' '}
          {form.getFieldsValue().mobile}
        </div>
        <div style={{ marginTop: 8 }}>
          备注名：{form.getFieldsValue().remark}
        </div>
        <div style={{ marginTop: 8 }}>
          成员角色：
          {form.getFieldsValue().role === 0 ? '普通成员' : '管理员'}
        </div>
        <div style={{ position: 'absolute', bottom: 24, left: 16, right: 16 }}>
          <Button
            style={{
              height: 40,
              marginRight: 10,

              width: 'calc( 50% - 5px )',
            }}
          >
            取消
          </Button>
          <Button
            type="primary"
            style={{ height: 40, width: 'calc( 50% - 5px )' }}
            onClick={() => {
              if (userInfo) {
                addMemberRun({
                  mvid: form.getFieldsValue().mvid,
                  remark: form.getFieldsValue().remark,
                  role: form.getFieldsValue().role,
                  uid: userInfo?.uid,
                }).then(() => {
                  setSubmitPopupVisible(false);
                  setFinishVisible(true);
                });
              } else {
                addMemberRun({
                  location: form.getFieldsValue().location,
                  mobile: form.getFieldsValue().mobile,
                  mvid: form.getFieldsValue().mvid,
                  remark: form.getFieldsValue().remark,
                  role: form.getFieldsValue().role,
                }).then(() => {
                  setSubmitPopupVisible(false);
                  setFinishVisible(true);
                });
              }
            }}
          >
            确认
          </Button>
        </div>
      </Popup>
      <Popup
        visible={finishVisible}
        onMaskClick={() => {
          setFinishVisible(false);
        }}
        showCloseButton
        onClose={() => {
          setFinishVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          minHeight: '236px',
          padding: '24px 16px',
        }}
      >
        <div
          style={{
            fontSize: 16,
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 24,
            alignItems: 'center',
          }}
        >
          <CheckCircleOutlined style={{ color: '#52C41A', marginRight: 16 }} />
          添加成功
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {userInfo
            ? '请告知客户刷新页面或重新登录后查看。'
            : `已用 ${form.getFieldsValue().location} ${
                form.getFieldsValue().mobile
              } 为客户创建账号并加入企业。请告知客户使用手机验证码登录后查看。`}
        </div>

        <div style={{ position: 'absolute', bottom: 24, left: 16, right: 16 }}>
          <Button
            type="primary"
            style={{
              height: 40,
              marginRight: 10,
              width: '100%',
            }}
            onClick={() => setFinishVisible(false)}
          >
            我知道了
          </Button>
        </div>
      </Popup>
      <AddMembersAuthorityModal
        permissionManegeModalOpen={permissionManegeModalOpen}
        setPermissionManegeModalOpen={setPermissionManegeModalOpen}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default AddMembersForm;
